<template>
  <v-app>
    <SideNav></SideNav>
    <div style="height: 100vh">
      <custom-toolbar :toolbarTitle="toolbarTitle"></custom-toolbar>
      <v-container style="width: 90%">
        <div>
          <v-btn @click="saveConfirm">크롤링 안된 패키지 다운로드</v-btn>
        </div>
      </v-container>
    </div>
    <v-dialog v-model="excelDialog" width="400" persistent>
      <MyDialog @hide="hideDialog" @confirm="excelDown" :is-confirm="true">
        <template #body>
          <h3>데이터를 다운로드 받으시겠습니까?</h3>
        </template>
        <template #btnname> 취소 </template>
        <template #confirmName> 확인 </template>
      </MyDialog>
    </v-dialog>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import SideNav from "@/components/admin/SideNav.vue";
import CustomToolbar from "@/components/admin/CustomToolbar.vue";
import Exceljs from "exceljs";
import { saveAs } from "file-saver";
import MyDialog from "@/components/MyDialog.vue";
import { INotCrawledList } from "@/interfaces/interface";

@Component({ components: { SideNav, CustomToolbar, MyDialog } })
export default class AdminMyPageView extends Vue {
  private toolbarTitle = "My Page";
  private excelDialog = false;

  async excelDown() {
    this.excelDialog = false;

    const list = await this.$store.dispatch("getNotCrawledPackageList");
    if (list.length) {
      const wb = new Exceljs.Workbook();
      const ws = wb.addWorksheet();
      const border: Partial<Exceljs.Borders> = {
        top: { style: "thin", color: { argb: "00000000" } },
        left: { style: "thin", color: { argb: "00000000" } },
        bottom: { style: "thin", color: { argb: "00000000" } },
        right: { style: "thin", color: { argb: "00000000" } },
      };
      const alignmentOption: Partial<Exceljs.Alignment> = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getColumn(1).width = 50;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 10;
      ws.getColumn(4).width = 8;
      ws.getColumn(5).width = 25;
      ws.getColumn(6).width = 25;

      /*ws.mergeCells("A3:G3");
      ws.getCell("A3").value = this.getHeaderLabel();
      ws.getCell("A3").border = border;
      ws.getCell("A3").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FDEBD0" },
      };
      ws.getCell("A3").alignment = {
        vertical: "middle",
        horizontal: "center",
      };*/
      ws.getCell("A3").value = "";
      const row = ws.addRow([
        "package",
        "label",
        "deprecated",
        "done",
        "createDate",
        "updateDate",
      ]);
      row.font = { bold: true };
      for (let i = 1; i <= ws.columnCount; i++) {
        ws.getCell(`${String.fromCharCode(i + 64)}4`).alignment =
          alignmentOption;
        ws.getCell(`${String.fromCharCode(i + 64)}4`).border = border;
        ws.getCell(`${String.fromCharCode(i + 64)}4`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "E5E7E9" },
        };
      }

      list.forEach((item: INotCrawledList) => {
        let date = "";
        const { createDate, updateDate } = item;

        /*date = `${Number(yyyymm).toString().slice(0, 4)}년${Number(yyyymm)
          .toString()
          .slice(4, 7)}월`;*/

        ws.addRow([
          item.packageName,
          item.label,
          item.deprecated,
          item.done,
          item.createDate,
          item.updateDate,
        ]);
      });
      for (let i = 5; i <= ws.rowCount; i++) {
        ws.getCell(`A${i}`).border = border;
        ws.getCell(`B${i}`).border = border;
        ws.getCell(`C${i}`).border = border;
        ws.getCell(`D${i}`).border = border;
        ws.getCell(`E${i}`).border = border;
        ws.getCell(`F${i}`).border = border;
      }

      const buf = await wb.xlsx.writeBuffer();

      saveAs(new Blob([buf]), `Packages.xlsx`);
    } else {
      await this.$dialog.notify.error("일치하는 데이터가 없습니다.");
      return false;
    }
  }
  saveConfirm() {
    this.excelDialog = true;
  }
  hideDialog() {
    this.excelDialog = false;
  }
}
</script>

<style scoped></style>
