<template>
  <v-navigation-drawer app color="grey darken-1" dark>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title" style="text-align: center"
          ><h1>In App</h1></v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item link to="/admin/monitoring">
        <v-list-item-content>
          <v-list-item-title><h3>Monitoring</h3></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/admin/mypage">
        <v-list-item-content>
          <v-list-item-title><h3>MyPage</h3></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Component from "vue-class-component";
import Vue from "vue";

@Component({})
export default class SideNav extends Vue {}
</script>

<style scoped></style>
