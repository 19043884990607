<template>
  <div class="nav-container">
    <img :src="imgSrc" id="logo" />
    <div class="title-container">
      <div v-if="mainTitle">
        <a
          v-if="
            mainTitle.options &&
            mainTitle.options.tab &&
            mainTitle.options.anchor
          "
          @click="linkTo(mainTitle.url)"
          target="_blank"
        >
          <span @click="clickMenu(mainTitle.title)">
            {{ mainTitle.title }}
          </span>
        </a>
        <a
          v-else-if="
            mainTitle.options &&
            !mainTitle.options.tab &&
            mainTitle.options.anchor
          "
          @click="linkTo(mainTitle.url)"
        >
          <span @click="clickMenu(mainTitle.title)">
            {{ mainTitle.title }}
          </span>
        </a>
        <router-link
          v-else-if="mainTitle.options && mainTitle.options.tab"
          :to="mainTitle.url"
          target="_blank"
        >
          <span @click="linkTo(mainTitle.url)">
            {{ mainTitle.title }}
          </span>
        </router-link>
        <router-link v-else :to="mainTitle.url">
          <span @click="linkTo(mainTitle.url)">
            {{ mainTitle.title }}
          </span>
        </router-link>
      </div>
    </div>
    <div class="main-menu-container pretendard-medium-daintree-18px">
      <div v-if="mainMenus">
        <div
          v-for="{ title, url, options } in getMenu(mainMenus)"
          :key="`key-${title}`"
        >
          <a
            v-if="options && options.tab && options.anchor"
            :href="url"
            target="_blank"
          >
            <span v-if="title === '로그아웃'" @click="logout">
              {{ title }}
            </span>
            <span v-else @click="clickMenu(title)">
              {{ title }}
            </span>
          </a>
          <a
            v-else-if="options && !options.tab && options.anchor"
            @click="linkTo(url)"
          >
            <span v-if="title === '로그아웃'" @click="logout">
              {{ title }}
            </span>
            <span v-else @click="linkTo(url)">
              {{ title }}
            </span>
          </a>
          <router-link
            v-else-if="options && options.tab"
            :to="url"
            target="_blank"
          >
            <span v-if="title === '로그아웃'" @click="logout">
              {{ title }}
            </span>
            <span v-else @click="clickMenu(title)">
              {{ title }}
            </span>
          </router-link>
          <router-link v-else :to="url">
            <span v-if="title === '로그아웃'" @click="logout">
              {{ title }}
            </span>
            <span v-else @click="linkTo(url)">
              {{ title }}
            </span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="sub-menu-container notosanskr-medium-gravel-14px">
      <div v-if="subMenus">
        <div
          v-for="{ title, url, options } in getMenu(subMenus)"
          :key="`key-${title}`"
        >
          <a
            v-if="options && options.tab && options.anchor"
            :href="url"
            target="_blank"
          >
            <span v-if="title === '로그아웃'" @click="logout">
              {{ title }}
            </span>
            <span v-else @click="clickMenu(title)">
              {{ title }}
            </span>
          </a>
          <a v-else-if="options && !options.tab && options.anchor" :href="url">
            <span v-if="title === '로그아웃'" @click="logout">
              {{ title }}
            </span>
            <span v-else @click="clickMenu(title)">
              {{ title }}
            </span>
          </a>
          <router-link
            v-else-if="options && options.tab"
            :to="url"
            target="_blank"
          >
            <span v-if="title === '로그아웃'" @click="logout">
              {{ title }}
            </span>
            <span v-else @click="clickMenu(title)">
              {{ title }}
            </span>
          </router-link>
          <router-link v-else :to="url">
            <span v-if="title === '로그아웃'" @click="logout">
              {{ title }}
            </span>
            <span v-else @click="clickMenu(title)">
              {{ title }}
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

interface IMenu {
  title: string;
  url: string;
  options?: {
    anchor?: boolean;
    tab?: boolean;
  };
}

@Component({})
export default class Nav extends Vue {
  @Prop() mainTitle?: IMenu;
  @Prop() mainMenus?: IMenu[];
  @Prop() subMenus?: IMenu[];
  @Prop() imgSrc?: any;
  @Prop() isLogin?: boolean;

  @Emit("clickMenu")
  private clickMenu(title: string) {
    return title;
  }

  private getMenu(menus: IMenu[]) {
    if (this.isLogin) {
      const currentMenu = menus.filter((menu) => menu.title !== "회원가입");
      const loginMenu = currentMenu.find((menu) => menu.title === "로그인");
      if (loginMenu) loginMenu.title = "로그아웃";
      return currentMenu;
    } else if (!this.isLogin) {
      const currentMenu = menus;
      const loginMenu = currentMenu.find((menu) => menu.title === "로그아웃");
      if (loginMenu) loginMenu.title = "로그인";
      return currentMenu;
    }
    return menus;
  }
  linkTo(url: string) {
    window.location.href = url;
  }
  @Emit("logout")
  private logout() {
    return;
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,700,500");

@font-face {
  font-family: "Pretendard-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff")
    format("woff");
}

a {
  color: #001f2b !important;
  text-decoration: none;
}

#logo {
  width: 34px;
  height: 34px;
  object-fit: contain;
}

.nav-container {
  background-color: white;
  border-bottom: #adafba 1px solid;
  width: 1920px;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 390px;
}

.title-container {
  font-family: "Noto Sans KR" !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: -1.61px;
  width: 119px;
  padding-left: 5px;
}

.main-menu-container {
  font-family: "Pretendard-Medium" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  width: 706px;
  padding: 0 124px;
}

.sub-menu-container {
  font-family: "Noto Sans KR" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  width: 278px;
}

.main-menu-container > div {
  display: flex;
  justify-content: space-between;
}

.sub-menu-container > div {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}
</style>
