<template>
  <div>
    <h1>Main</h1>
    <v-btn @click="logout">로그아웃</v-btn>
    <br />
    <v-btn @click="myPage">마이페이지</v-btn>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import router from "@/router";

@Component({})
export default class HomeView extends Vue {
  //로그아웃
  async logout() {
    localStorage.clear();
    this.$store.commit("logout");
    await router.push("/");
  }
  myPage() {
    this.$router.push("/mypage?token=" + this.$store.state.token);
  }
}
</script>
