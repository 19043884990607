import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/mobile/HomeView.vue";
import LogInView from "../views/mobile/LogInView.vue";
import JoinView from "../views/mobile/JoinView.vue";
import JoinContinue from "../views/mobile/JoinContinueView.vue";
import MyPageView from "../views/mobile/MyPageView.vue";
import TokenCheck from "@/views/mobile/TokenCheck.vue";
import AdminLogin from "../views/admin/AdminLoginView.vue";
import AdminMonitoring from "../views/admin/AdminMonitoringView.vue";
import AdminMyPageView from "@/views/admin/AdminMyPageView.vue";
import MainView from "@/views/dashboard/MainView.vue";
import store from "@/store";
import ModifyProfileView from "@/views/mobile/ModifyProfileView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "LogIn",
    component: LogInView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/home",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: HomeView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/mypage",
    name: "TokenCheck",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TokenCheck,
    meta: {
      auth: true,
    },
  },
  {
    path: "/mypage/main",
    name: "MyPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: MyPageView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/mypage/modify",
    name: "modifyProfile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ModifyProfileView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/join",
    name: "Join",
    component: JoinView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/joincontinue",
    name: "JoinContinue",
    component: JoinContinue,
    meta: {
      auth: false,
    },
  }, // -----------------------------------------ADMIN-----------------------------------------
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
    meta: {
      auth: false,
    },
  },
  {
    path: "/admin/monitoring",
    name: "AdminMonitoring",
    component: AdminMonitoring,
    meta: {
      auth: true,
      role: "admin",
    },
  },
  {
    path: "/admin/mypage",
    name: "AdminMyPage",
    component: AdminMyPageView,
    meta: {
      auth: true,
    },
  }, // -----------------------------------------DASHBOARD-----------------------------------------
  {
    path: "/dashboard",
    name: "MainView",
    component: MainView,
    meta: {
      auth: true,
      role: "dashboard",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  //base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let isAuth = false;
  let admin = false;
  let dashboard = false;
  //이동할 페이지 auth 확인
  const { meta } = to;
  if (meta) {
    const { auth, role } = meta;
    if (auth) {
      isAuth = auth;
    }
    if (role) {
      if (role === "admin") {
        admin = true;
        dashboard = false;
      } else if (role === "dashboard") {
        admin = false;
        dashboard = true;
      }
    }
  }

  //auth 인증이 필요하면 인증요청 후 인증 안될시 로그인 화면으로
  if (isAuth) {
    // 토큰값 backend로 검증 //수정필요
    if (!dashboard) {
      try {
        const result = await store.dispatch("verify");
        const isAdmin = store.state.isAdmin;
        //검증결과 false 면

        if (!result) {
          if (admin) {
            return next("/admin/login");
          }
          return next("");
        }
        if (admin) {
          if (!isAdmin) {
            return next("/admin/login");
          }
        }
        return next();
      } catch (e) {
        console.log(e);
        return false;
      }
    } else {
      const result = await store.dispatch("dashboardVerify");

      if (!result) {
        document.location.href = "https://pmidata.co.kr";
      }
      return next();
    }
  }
  //auth 인증 필요없으면 바로 이동
  else {
    return next();
  }
});
export default router;
