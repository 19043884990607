<template>
  <div>
    <v-card>
      <v-card-title class="justify-center">비밀번호 변경</v-card-title>
      <hr />
      <v-card-text class="pa-5">
        <div>
          <div class="d-flex">
            <h4>기존 비밀번호 입력</h4>
            <span style="color: red" v-show="!oldPasswordState">
              <h5>다시 확인해 주세요.</h5>
            </span>
          </div>
          <v-text-field
            v-model="userOldPassword"
            type="password"
            color="info"
            dense
            outlined
          ></v-text-field>
          <h4>신규 비밀번호 입력</h4>
          <v-text-field
            v-model="userNewPassword"
            type="password"
            color="info"
            dense
            outlined
          ></v-text-field>
          <div class="d-flex">
            <h4>신규 비밀번호 확인</h4>
            <span style="color: red" v-show="!newPasswordState">
              <h5>비밀번호가 일치하지 않습니다.</h5>
            </span>
          </div>
          <v-text-field
            v-model="userNewPasswordCheck"
            type="password"
            color="info"
            dense
            outlined
          ></v-text-field>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center mr-2 pb-4" style="width: 100%">
        <v-btn
          color="info"
          dark
          rounded
          @click="modifyPassword"
          style="width: 40%"
        >
          <slot name="btnname"></slot>
        </v-btn>
        <v-btn
          color="grey darken-2"
          dark
          rounded
          @click="closeSelf"
          style="width: 40%"
        >
          <slot name="closebtnname"></slot>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="alertDialog" width="500" persistent>
      <MyDialog @hide="hideDialog" :logo="dialogLogo">
        <template #body>
          <h3>{{ dialMsg }}</h3>
        </template>
        <template #btnname> 닫기 </template>
      </MyDialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import MyDialog from "@/components/MyDialog.vue";

@Component({ components: { MyDialog } })
export default class PasswordModifyDialog extends Vue {
  private userId = this.$store.state.loginId;
  private userOldPassword = "";
  private oldPasswordState = true;
  private userNewPassword = "";
  private userNewPasswordCheck = "";
  private newPasswordState = true;
  private alertDialog = false;
  private btnContent = "";
  private dialMsg = "";
  private dialogLogo = "";

  async modifyPassword() {
    const result = await this.$store.dispatch("modifyPassword", {
      userId: this.userId,
      userOldPassword: this.userOldPassword,
      userNewPassword: this.userNewPassword,
      userNewPasswordCheck: this.userNewPasswordCheck,
    });
    if (result.result === 1) {
      //성공
      this.showDialog("변경이 완료되었습니다.", "Check");
      this.closeSelf();
    } else if (result.result === 2) {
      //현재비밀번호 확인
      this.oldPasswordState = false;
      this.newPasswordState = true;
    } else if (result.result === 3) {
      //새비밀번호 확인
      this.oldPasswordState = true;
      this.newPasswordState = false;
    } else if (result.result === 4) {
      //둘 다 확인
      this.oldPasswordState = false;
      this.newPasswordState = false;
    } else {
      this.showDialog(result.msg, "X");
    }
  }
  showDialog(msg: string, logo: string) {
    this.alertDialog = true;
    this.btnContent = "확인";
    this.dialogLogo = logo;
    this.dialMsg = msg;
  }
  hideDialog() {
    this.alertDialog = false;
  }
  closeSelf() {
    this.userOldPassword = "";
    this.userNewPassword = "";
    this.userNewPasswordCheck = "";
    this.oldPasswordState = true;
    this.newPasswordState = true;
    this.$emit("hide");
  }
}
</script>

<style scoped></style>
