<template>
  <div>
    <v-form ref="form" lazy-validation>
      <h4>성별</h4>
      <v-radio-group v-model="userInfo.userGender" row>
        <v-radio label="남자" color="info" value="1"></v-radio>
        <v-radio label="여자" color="info" value="2"></v-radio>
      </v-radio-group>
      <h4>생년월일</h4>
      <v-text-field
        v-model="userInfo.userBirthDate"
        label="생년월일 6자리 (ex. 901201)"
        color="info"
        type="number"
        maxlength="6"
        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        required
      ></v-text-field>
      <h4>거주지</h4>
      <div class="d-flex justify-md-space-between">
        <v-text-field
          v-model="userInfo.userAddress"
          label="거주지 검색"
          color="info"
          @click="execDaumPostcode"
          readonly
          required
        ></v-text-field>
        <v-btn
          depressed
          color="grey darken-2"
          class="ma-0"
          dark
          small
          @click="execDaumPostcode"
          >주소검색
        </v-btn>
      </div>
      <h4>결혼상태</h4>
      <v-radio-group v-model="userInfo.userMarried" row>
        <v-radio label="기혼" color="info" value="1"></v-radio>
        <v-radio label="미혼" color="info" value="2"></v-radio>
        <v-radio label="기타(이혼,사별)" color="info" value="3"></v-radio>
        <!--      </v-radio-group>
      <h4>자녀 유무</h4>
      <v-radio-group v-model="userInfo.userChildren" row>
        <v-radio label="자녀 있음" value="1"></v-radio>
        <v-radio label="자녀 없음" value="2"></v-radio>
      </v-radio-group>-->
      </v-radio-group>
      <h4>관심사 (최대 3가지 선택)</h4>
      <div class="d-flex justify-space-around mb-2">
        <v-btn-toggle
          v-model="userInfo.userInterest"
          multiple
          style="width: 100%"
        >
          <v-btn small style="width: 33%" @click="checkInterestCount" value="1">
            사회/정치
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="2"
            >건강/의학
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="3"
            >경제/금융
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex justify-space-around mb-2">
        <v-btn-toggle
          v-model="userInfo.userInterest"
          multiple
          style="width: 100%"
        >
          <v-btn small style="width: 33%" @click="checkInterestCount" value="4"
            >교육/학문
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="5"
            >육아/결혼/가족
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="6"
            >반려동물
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex justify-space-around mb-2">
        <v-btn-toggle
          v-model="userInfo.userInterest"
          multiple
          style="width: 100%"
        >
          <v-btn small style="width: 33%" @click="checkInterestCount" value="7"
            >요리/레시피
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="8"
            >맛집
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="9"
            >뷰티
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex justify-space-around mb-2">
        <v-btn-toggle
          v-model="userInfo.userInterest"
          multiple
          style="width: 100%"
        >
          <v-btn small style="width: 33%" @click="checkInterestCount" value="10"
            >인테리어/DIY
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="11"
            >여행
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="12"
            >다이어트
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex justify-space-around mb-2">
        <v-btn-toggle
          v-model="userInfo.userInterest"
          multiple
          style="width: 100%"
        >
          <v-btn small style="width: 33%" @click="checkInterestCount" value="13"
            >IT
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="14"
            >게임
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="15"
            >자동차
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex justify-space-around mb-2">
        <v-btn-toggle
          v-model="userInfo.userInterest"
          multiple
          style="width: 100%"
        >
          <v-btn small style="width: 33%" @click="checkInterestCount" value="16"
            >스포츠/레저
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="17"
            >스타/연예인
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="18"
            >문화/공연/예술
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex justify-space-around mb-2">
        <v-btn-toggle
          v-model="userInfo.userInterest"
          multiple
          style="width: 100%"
        >
          <v-btn small style="width: 33%" @click="checkInterestCount" value="19"
            >환경
          </v-btn>
          <v-btn small style="width: 33%" @click="checkInterestCount" value="20"
            >기타
          </v-btn>
        </v-btn-toggle>
      </div>
      <h4>가구 구성</h4>
      <v-radio-group v-model="userInfo.userFamily" row>
        <v-radio label="1인 가구" color="info" value="1"></v-radio>
        <v-radio label="2인 가구" color="info" value="2"></v-radio>
        <v-radio label="3인 가구 이상" color="info" value="3"></v-radio>
      </v-radio-group>
      <h4>Life Stage</h4>
      <v-radio-group v-model="userInfo.userLifeStage">
        <v-radio
          label="동거인(ex 부모/형제/친구 등)과 함께 거주하는 미성년자 또는 성인 미혼자"
          color="info"
          value="1"
        ></v-radio>
        <v-radio
          label="혼자사는 성인(1인 가구)"
          color="info"
          value="2"
        ></v-radio>
        <v-radio label="자녀가 없는 기혼자" color="info" value="3"></v-radio>
        <v-radio
          label="미취학 아동(0~6세)이 있는 기혼자"
          color="info"
          value="4"
        ></v-radio>
        <v-radio
          label="초등학생 자녀(7~12세)가 있는 기혼자"
          color="info"
          value="5"
        ></v-radio>
        <v-radio
          label="중고생 자녀(13~18세)가 있는 기혼자"
          color="info"
          value="6"
        ></v-radio>
        <v-radio
          label="성인 자녀(19세 이상)가 있는 기혼자"
          color="info"
          value="7"
        ></v-radio>
        <v-radio
          label="자녀가 독립하여 부부 둘만 사는 기혼자"
          color="info"
          value="8"
        ></v-radio>
      </v-radio-group>
    </v-form>
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      width="500"
      v-model="mapDialog"
    >
      <MapDialog @hide="closeMapDialog" ref="kakaoMap"></MapDialog>
    </v-dialog>
    <v-dialog v-model="myDialog" width="400">
      <MyDialog @hide="hideDialog" :logo="dialogLogo">
        <template #body>
          <h3>{{ dialMsg }}</h3>
        </template>
        <template #btnname> 닫기 </template>
      </MyDialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import MapDialog from "@/components/MapDialog.vue";
import MyDialog from "@/components/MyDialog.vue";

@Component({ components: { MapDialog, MyDialog } })
export default class Profile extends Vue {
  $refs!: {
    kakaoMap: HTMLFormElement;
  };
  private userInfo = {
    userGender: "",
    userBirthDate: "",
    userAddress: "",
    userMarried: "",
    userInterest: [],
    userFamily: "",
    userLifeStage: "",
    used: false,
  };
  private userInterest_temp = [];
  private mapDialog = false;
  private myDialog = false;
  private dialMsg = "";
  private dialogLogo = "";
  private btnContent = "";

  execDaumPostcode() {
    this.mapDialog = true;
    /*new window.daum.Postcode({
      oncomplete: (data: any) => {
        if (data.userSelectedType === "R") {
          this.userInfo.userAddress = data.roadAddress;
        } else {
          this.userInfo.userAddress = data.jibunAddress;
        }
      },
    }).open();*/
  }
  closeMapDialog() {
    this.userInfo.userAddress = this.$refs.kakaoMap.address;
    this.mapDialog = false;
  }

  checkInterestCount() {
    setTimeout(() => {
      if (this.userInfo.userInterest.length > 3) {
        this.userInfo.userInterest.pop();
        this.showDialog("관심사는 최대 3가지만 선택 가능합니다.");
        return false;
      }
    }, 50);
  }

  hideDialog() {
    this.myDialog = false;
  }
  showDialog(msg: string) {
    this.myDialog = true;
    this.dialogLogo = "X";
    this.btnContent = "닫기";
    this.dialMsg = msg;
  }
}
</script>

<style scoped></style>
