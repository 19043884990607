<template>
  <div id="body">
    <div class="container-center-horizontal">
      <div class="rogeuin screen">
        <div class="top-bar">
          <Nav2
            ref="nav"
            :mainTitle="mainTitle"
            :mainMenu="mainMenu"
            :subMenu="subMenu"
            :isLogin="isLogin"
            :imgSrc="imgSrc"
            @clickMenu="clickMenu"
            @logout="logout"
          />
        </div>
      </div>
    </div>
    <v-container style="width: 75%">
      <div>
        <DashboardFilter
          ref="filter"
          @draw="drawing"
          :loading="loading"
        ></DashboardFilter>
      </div>
      <div class="mt-15 d-flex row ma-0 justify-center">
        <div style="width: 100%" class="align-stretch col-12 pr-0 pl-0">
          <div class="text-end mb-3">
            <v-btn
              class="mr-3"
              color="primary"
              :disabled="loading"
              @click="saveConfirm('excel')"
              outlined
            >
              엑셀 다운로드<v-icon> mdi-arrow-collapse-down</v-icon>
            </v-btn>
            <v-btn
              color="error"
              :disabled="loading"
              @click="saveConfirm('pdf')"
              outlined
            >
              PDF 다운로드<v-icon> mdi-arrow-collapse-down</v-icon>
            </v-btn>
          </div>
          <div class="row ma-0">
            <LineChart
              ref="lineChart"
              :loading="loading"
              :date="date"
            ></LineChart>
          </div>
          <div
            class="mt-15 d-flex row ma-0 justify-md-space-between"
            style="width: 100%"
          >
            <RankTable
              class="col-12 col-lg-6 pl-0 pr-5"
              :title="'앱 사용시간 순위'"
              :date="date"
              :header-label="useSubLabel"
              :body-label="'분'"
              :loading="loading"
              :table-data="useData"
            ></RankTable>

            <RankTable
              class="col-12 col-lg-6 pr-0 pl-5"
              :title="'앱 클릭량 순위'"
              :date="date"
              :header-label="clickSubLabel"
              :body-label="'회'"
              :loading="loading"
              :table-data="clickData"
            ></RankTable>
          </div>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="excelDialog" width="400" persistent>
      <MyDialog
        @hide="hideDialog"
        @confirm="excelDown"
        logo="dialogLogo"
        :is-confirm="true"
      >
        <template #body>
          <h3>데이터를 다운로드 받으시겠습니까?</h3>
        </template>
        <template #btnname> 취소 </template>
        <template #confirmName> 확인 </template>
      </MyDialog>
    </v-dialog>
    <v-dialog v-model="pdfDialog" width="400" persistent>
      <MyDialog
        @hide="hideDialog"
        @confirm="pdfDown"
        logo="dialogLogo"
        :is-confirm="true"
      >
        <template #body>
          <h3>PDF를 다운로드 받으시겠습니까?</h3>
        </template>
        <template #btnname> 취소 </template>
        <template #confirmName> 확인 </template>
      </MyDialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import LineChart from "../../components/dashboard/LineChart.vue";
import { Component } from "vue-property-decorator";
import DashboardFilter from "@/components/dashboard/DashboardFilter.vue";
import CategoryFilter from "@/components/dashboard/CategoryFilter.vue";
import RankTable from "@/components/dashboard/RankTable.vue";
import {
  IRankData,
  IResponseExcelList,
} from "@/interfaces/dashboard/interface";
import Exceljs from "exceljs";
import { saveAs } from "file-saver";
import MyDialog from "@/components/MyDialog.vue";
import Nav2, { IBasicMenu, IMainMenu } from "@/components/dashboard/Nav2.vue";
import BasicMenu from "@/components/dashboard/BasicMenu.vue";
import router from "@/router";

@Component({
  components: {
    BasicMenu,
    LineChart,
    DashboardFilter,
    CategoryFilter,
    RankTable,
    MyDialog,
    Nav2,
  },
})
export default class MainView extends Vue {
  // Logo 이미지
  private imgSrc = require("@/assets/img/logo.svg");

  // 메인 타이틀
  private mainTitle: IBasicMenu = {
    title: "통합 데이터 허브",
    url: "https://pmidata.co.kr/main",
    options: {
      anchor: true,
      tab: true,
    },
  };

  // 메인 메뉴
  private mainMenu: IMainMenu[] = [
    {
      title: "데이터 탐색",
      detailMenu: [
        {
          title: "뉴스/SNS 트렌드 데이터",
          url: `http://trend.pmidata.co.kr/mainpage.do?token=${this.$store.getters.token}`,
          options: {
            anchor: true,
          },
        },
        {
          title: "모바일 행동 데이터",
          url: "/dashboard",
        },
        {
          title: "공공 데이터",
          url: `https://public.pmidata.co.kr/api/auth/${this.$store.getters.token}`,
          options: {
            anchor: true,
          },
        },
        {
          title: "결제 데이터",
          url: `https://transaction.pmidata.co.kr/api/auth/${this.$store.getters.token}`,
          options: {
            anchor: true,
          },
        },
      ],
    },
    {
      title: "데이터 마켓",
      detailMenu: [
        {
          title: "데이터 셋",
          url: "https://pmidata.co.kr/data-set",
          options: {
            anchor: true,
          },
        },
        {
          title: "융합 데이터",
          url: "https://pmidata.co.kr/fusion-data",
          options: {
            anchor: true,
          },
        },
        {
          title: "맞춤형 데이터 서비스",
          url: "https://pmidata.co.kr/custom-data-service",
          options: {
            anchor: true,
          },
        },
      ],
    },
    {
      title: "데이터 작업소",
      detailMenu: [
        {
          title: "시각화 만들기",
          url: "https://pmidata.co.kr/visualization",
          options: {
            anchor: true,
          },
        },
        {
          title: "마이 데이터 랩",
          url: "https://pmidata.co.kr/my-data-hub/my-home",
          options: {
            anchor: true,
          },
        },
      ],
    },
    {
      title: "알림",
      detailMenu: [
        {
          title: "플랫폼 소개",
          url: "https://pmidata.co.kr/about-plat-form",
          options: {
            anchor: true,
          },
        },
        {
          title: "공지사항",
          url: "https://pmidata.co.kr/notice",
          options: {
            anchor: true,
          },
        },
        {
          title: "자주하는 질문",
          url: "https://pmidata.co.kr/faq",
          options: {
            anchor: true,
          },
        },
        {
          title: "문의사항",
          url: "https://pmidata.co.kr/qna",
          options: {
            anchor: true,
          },
        },
      ],
    },
  ];

  /* 서브 메뉴
   * 로그인 메뉴 로그인/로그아웃 변경 및 회원가입 메뉴 숨김은 메뉴 이름이 로그인, 회원가입일 때만 적용.
   */
  private subMenu: IBasicMenu[] = [
    {
      title: "로그아웃",
      url: "https://pmidata.co.kr/logout",
      options: {
        anchor: true,
      },
    },
    {
      title: "마이페이지",
      url: "https://pmidata.co.kr/mypage",
      options: {
        anchor: true,
      },
    },
    {
      title: "이용안내",
      url: "https://pmidata.co.kr/",
      options: {
        anchor: true,
      },
    },
    {
      title: "사이트맵",
      url: "#",
      options: {
        anchor: true,
        tab: false,
      },
    },
  ];

  // 로그인 여부 boolean
  private get isLogin() {
    return !!Vue.$cookies.get("vuex");
  }

  // 로그아웃 이벤트
  private async logout() {
    await this.$store.dispatch("dashboardLogout");
  }

  // 메뉴 클릭 시 누른 메뉴의 타이틀을 파라미터로 받음(클릭 이벤트로 사용)
  private clickMenu(title: string) {
    switch (title) {
      case "마이 데이터 허브": {
        this.$store.commit("clickMyDataHubReset", true);
        break;
      }
      case "사이트맵": {
        this.$refs.nav.hover(true);
        break;
      }
    }
  }

  $refs!: {
    nav: Nav2;
    lineChart: HTMLFormElement;
    filter: HTMLFormElement;
  };
  $dialog: any;
  private excelDialog = false;
  private pdfDialog = false;
  private loading = true;
  private date = "";
  private useSubLabel = "";
  private clickSubLabel = "";
  private useData: Array<IRankData> = [];
  private clickData: Array<IRankData> = [];

  async drawing() {
    this.loading = true;
    this.loading = await this.drawingChart();
    this.loading = await this.drawingRankTable();
    this.date = `${this.$store.state.dashboard.dateLabel[0]} > ${this.$store.state.dashboard.dateLabel[1]}`;
    if (!this.loading) {
      this.loading = false;
      await this.$dialog.notify.success("적용 되었습니다.");
    } else {
      this.loading = false;
      await this.$dialog.notify.error("일치하는 데이터가 없습니다.");
    }
  }
  async drawingChart() {
    this.$refs.lineChart.chartType = "sum";
    const result = await this.$refs.lineChart.drawingChart("sum");
    if (result) {
      return false;
    } else {
      return true;
    }
  }
  async drawingRankTable() {
    const payload = await this.$store.dispatch("dashboard/getPayloadValue");

    try {
      const { useList, clickList } = await this.$store.dispatch(
        "dashboard/getRankData",
        payload
      );
      if (useList.length !== 0 && clickList !== 0) {
        this.useData = useList;
        this.clickData = clickList;

        if (this.$store.state.dashboard.filterValue.searchPeriod === "1") {
          this.useSubLabel = "Time(주 총합)";
          this.clickSubLabel = "Click(주 총합)";
        } else if (
          this.$store.state.dashboard.filterValue.searchPeriod === "2"
        ) {
          this.useSubLabel = "Time(월 총합)";
          this.clickSubLabel = "Click(월 총합)";
        } else if (
          this.$store.state.dashboard.filterValue.searchPeriod === "3"
        ) {
          this.useSubLabel = "Time(연 총합)";
          this.clickSubLabel = "Click(연 총합)";
        }

        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.log(e);
      return true;
    }
  }

  async excelDown() {
    this.excelDialog = false;
    const payload = await this.$store.dispatch("dashboard/getPayloadValue");
    let list: Array<IResponseExcelList>;

    list = await this.$store.dispatch("dashboard/getExcelData", {
      ...payload,
    });
    if (list.length) {
      const wb = new Exceljs.Workbook();
      const ws = wb.addWorksheet();
      const border: Partial<Exceljs.Borders> = {
        top: { style: "thin", color: { argb: "00000000" } },
        left: { style: "thin", color: { argb: "00000000" } },
        bottom: { style: "thin", color: { argb: "00000000" } },
        right: { style: "thin", color: { argb: "00000000" } },
      };
      const alignmentOption: Partial<Exceljs.Alignment> = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getColumn(1).width = 20;
      ws.getColumn(2).width = 40;
      ws.getColumn(3).width = 25;
      ws.getColumn(4).width = 14;
      ws.getColumn(5).width = 16;
      ws.getColumn(6).width = 13;
      ws.getColumn(7).width = 15;

      ws.mergeCells("A3:G3");
      ws.getCell("A3").value = this.getHeaderLabel();
      ws.getCell("A3").border = border;
      ws.getCell("A3").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FDEBD0" },
      };
      ws.getCell("A3").alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      const row = ws.addRow([
        "기간",
        "앱 이름",
        "앱 카테고리",
        "총 사용시간(분)",
        "평균 사용시간(분)",
        "총 클릭수(회)",
        "평균 클릭수(회)",
      ]);
      row.font = { bold: true };
      for (let i = 1; i <= ws.columnCount; i++) {
        ws.getCell(`${String.fromCharCode(i + 64)}4`).alignment =
          alignmentOption;
        ws.getCell(`${String.fromCharCode(i + 64)}4`).border = border;
        ws.getCell(`${String.fromCharCode(i + 64)}4`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "E5E7E9" },
        };
      }

      list.forEach((item: IResponseExcelList) => {
        let date = "";
        const { searchPeriod } = this.$store.state.dashboard.filterValue;
        const { yyyymmdd, yyyymm } = item;
        if (searchPeriod === "1") {
          date = `${Number(yyyymmdd).toString().slice(0, 4)}년${Number(yyyymmdd)
            .toString()
            .slice(4, 6)}월${Number(yyyymmdd).toString().slice(6, 8)}일`;
        } else if (searchPeriod === "2") {
          if (yyyymm) {
            date = yyyymm.toString();
          }
        } else if (searchPeriod === "3") {
          date = `${Number(yyyymm).toString().slice(0, 4)}년${Number(yyyymm)
            .toString()
            .slice(4, 7)}월`;
        }

        ws.addRow([
          date,
          item.label,
          item.category,
          item.totalMinute,
          item.avgMinute,
          item.totalCount,
          item.avgCount,
        ]);
      });
      for (let i = 5; i <= ws.rowCount; i++) {
        ws.getCell(`A${i}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FADBD8" },
        };
        ws.getCell(`A${i}`).border = border;
        ws.getCell(`B${i}`).border = border;
        ws.getCell(`C${i}`).border = border;
        ws.getCell(`D${i}`).border = border;
        ws.getCell(`E${i}`).border = border;
        ws.getCell(`F${i}`).border = border;
        ws.getCell(`G${i}`).border = border;
      }

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `App_${this.$store.state.dashboard.dateLabel[0]}_${this.$store.state.dashboard.dateLabel[1]}.xlsx`
      );
    } else {
      await this.$dialog.notify.error("일치하는 데이터가 없습니다.");
      return false;
    }
  }

  getHeaderLabel() {
    const {
      selectedGender,
      selectedAddress,
      selectedAge,
      selectedInterest,
      selectedFamily,
      selectedLifeStage,
      selectedMarried,
      category,
    } = this.$store.state.dashboard.filterValue;
    let textArray;
    let headerLabel = "전체 중(";

    textArray = selectedGender.map((v: { text: string }) => v.text);
    headerLabel = headerLabel + "성별=[" + textArray.join(",") + "],";

    textArray = selectedAddress.map((v: { text: string }) => v.text);
    headerLabel = headerLabel + "거주지역=[" + textArray.join(",") + "],";
    textArray = selectedInterest.map((v: { text: string }) => v.text);
    headerLabel = headerLabel + "관심사=[" + textArray.join(",") + "],";
    textArray = selectedAge.map((v: { text: string }) => v.text);
    headerLabel = headerLabel + "연령=[" + textArray.join(",") + "],";
    textArray = selectedFamily.map((v: { text: string }) => v.text);
    headerLabel = headerLabel + "가구구성=[" + textArray.join(",") + "],";
    textArray = selectedMarried.map((v: { text: string }) => v.text);
    headerLabel = headerLabel + "결혼여부=[" + textArray.join(",") + "],";
    textArray = selectedLifeStage.map((v: { text: string }) => v.text);
    headerLabel = headerLabel + "LifeStage=[" + textArray.join(",") + "],";
    if (category.length > 0) {
      headerLabel = headerLabel + "카테고리=[" + category.join(",") + "]";
    } else {
      headerLabel = headerLabel + "카테고리=[전체]";
    }
    headerLabel = headerLabel + ")기준";
    return headerLabel;
  }
  saveConfirm(mode: string) {
    if (mode === "excel") {
      this.excelDialog = true;
    } else if (mode === "pdf") {
      this.pdfDialog = true;
    }
  }
  hideDialog() {
    this.excelDialog = false;
    this.pdfDialog = false;
  }
  pdfDown() {
    this.$refs.lineChart.pdfDown();
    this.pdfDialog = false;
  }
}
</script>

<style scoped>
#body {
  background: #f8f8f8;
}
.rogeuin {
  align-items: flex-start;
  background-color: var(
    --material-themeextendedcustom-color-2grouptone10065181866
  );
  border: 1px none;
  /*min-height: 1440px;*/
  /*min-height: 100vh;*/
  /*min-height: 900px;*/
  width: 100%;
}

.top-bar {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);

  top: 0px;
  z-index: 99;
}
</style>
