import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ko from "vuetify/src/locale/ko";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ko },
  },
  theme: {
    themes: {
      light: {
        primary: "#00A880", // #E53935
        info: "#1976D2",
      },
    },
  },
});
