<template>
  <v-app>
    <v-container class="pa-0 col-md-4 col-lg-3 col-xl-3" fill-height>
      <v-card
        class="mx-auto"
        align-center
        min-width="100%"
        min-height="100vh"
        tile
      >
        <v-toolbar flat color="info" dark>
          <v-toolbar-title class="font-weight-bold"
            >Profile 입력</v-toolbar-title
          >
        </v-toolbar>
        <v-main align-center>
          <div class="pa-5">
            <Profile ref="profile"></Profile>
            <v-btn
              class="mt-2"
              block
              dark
              color="indigo"
              @click="formValidator"
            >
              회원가입 완료
            </v-btn>
            <v-btn
              class="mt-2"
              block
              color="grey darken-1"
              dark
              depressed
              to="/"
            >
              취소
            </v-btn>
          </div>
        </v-main>
      </v-card>
      <v-dialog v-model="myDialog" width="400">
        <MyDialog @hide="hideDialog" :logo="dialogLogo">
          <template #body>
            <h3>{{ dialMsg }}</h3>
          </template>
          <template #btnname> 닫기 </template>
        </MyDialog>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import MyDialog from "../../components/MyDialog.vue";
import { Component } from "vue-property-decorator";
import Profile from "../../components/Profile.vue";

@Component({ components: { MyDialog, Profile } })
export default class JoinContinueView extends Vue {
  $refs!: {
    profile: HTMLFormElement;
  };
  private userInfo = {};
  private myDialog = false;
  private dialMsg = "";
  private dialogLogo = "";
  private btnContent = "";

  async formValidator() {
    this.userInfo = {
      userGender: this.$refs.profile.userInfo.userGender,
      userBirthDate: this.$refs.profile.userInfo.userBirthDate,
      userAddress: this.$refs.profile.userInfo.userAddress,
      userMarried: this.$refs.profile.userInfo.userMarried,
      userInterest: this.$refs.profile.userInfo.userInterest,
      userFamily: this.$refs.profile.userInfo.userFamily,
      userLifeStage: this.$refs.profile.userInfo.userLifeStage,
      used: false,
    };
    try {
      let res = await this.$store.dispatch(
        "userInputDataContinue",
        this.userInfo
      );
      if (!res.result) {
        this.showDialog(res.msg);
        return false;
      }
      res = await this.$store.dispatch("registerUser");
      if (!res.result) {
        this.showDialog(res);
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  hideDialog() {
    this.myDialog = false;
  }
  showDialog(msg: string) {
    this.myDialog = true;
    this.dialogLogo = "X";
    this.btnContent = "닫기";
    this.dialMsg = msg;
  }
}
</script>

<style scoped></style>
