<template>
  <v-card id="chartCard" ref="chartCard" class="col-12" outlined>
    <v-card-title class="align-center justify-center">
      <div class="col-sm-12 col-lg-2"></div>
      <div class="text-center col-sm-12 col-lg-8">
        앱 사용시간 추이 (TOP 10)
      </div>
      <div class="text-center col-sm-12 col-lg-2">
        <v-btn-toggle color="black" mandatory v-model="chartType">
          <v-btn small text value="avg" @click="drawingChart('avg')">
            평균
          </v-btn>
          <v-btn small text value="sum" @click="drawingChart('sum')">
            총합
          </v-btn>
        </v-btn-toggle>
      </div>
    </v-card-title>
    <v-card-subtitle class="text-end" v-text="date" v-model="date">
    </v-card-subtitle>
    <div style="width: 100%">
      <v-layout
        class="ma-10"
        align-center
        justify-center
        fill-height
        v-if="loading"
      >
        <v-progress-circular
          class="align-self-center"
          :size="70"
          :width="7"
          color="grey lighten-1"
          indeterminate
        ></v-progress-circular>
      </v-layout>
      <LineChartGenerator
        v-else
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </div>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import { IChartData, IChartDataSet } from "@/interfaces/dashboard/interface";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

@Component({
  components: {
    LineChartGenerator,
  },
})
export default class LineChart extends Vue {
  @Prop() loading?: boolean;
  @Prop() date?: string;
  $refs!: {
    chartCard: HTMLFormElement;
  };
  private chartType = "sum";
  private chartData: IChartData = {
    labels: [],
    datasets: [],
  };

  mounted() {
    this.chartType = "sum";
  }

  private chartOptions = {
    responsive: true,
    indexAxis: "x",
    scales: {
      y: {
        labelString: "Value",
        display: true,
        position: "left",
        title: {
          display: true,
          align: "center",
          position: "top",
          color: "#808080",
          font: {
            size: 13,
            family: "'Noto Sans KR', sans-serif",
            weight: 300,
          },
          text: "시간",
        },
        ticks: {
          callback: (value: any) => {
            //return `${Math.floor(value / 60)}시간${value % 60}분`;
            return `${Math.floor(value / 60)}`;
          },
          stepSize: 60,
        },
      },
    },
    plugins: {
      legend: {
        position: "right",
        labels: {
          padding: 15, // legend 사이 padding
          usePointStyle: true,
          pointStyle: "circle",
          //pointRadius: 3,
        },
      },
      tooltip: {
        mode: "index",
        position: "nearest",
        intersect: false,
        bodyAlign: "right",
        backgroundColor: "rgb(255, 255, 255, 0.8)",
        titleColor: "#000",
        titleFont: { weight: "bold" },
        bodyColor: "#000",
        borderColor: "rgb(80, 80, 80)",
        borderWidth: 1,
        callbacks: {
          title: (context: any) => {
            return context[0].label;
          },
          label: (context: any) => {
            const hours = Math.floor(context.parsed.y / 60);
            const minute = context.parsed.y % 60;

            return `${context.dataset.label}: ${hours}시간 ${minute} 분`;
          },
          /*labelTextColor: function (tooltipItem, chart) {
            return "#000000";
          },*/
        },
      },
    },
  };

  private width = 500;
  private height = 200;
  private cssClasses = "";
  private styles = {};
  private plugins = [];
  private datasetIdKey = "label";
  private chartId = "line-chart";
  private lineColor = [
    "#4472C4",
    "#ED7D31",
    "#A5A5A5",
    "#FFC000",
    "#5B9BD5",
    "#70AD47",
    "#997300",
    "#636363",
    "#9E480E",
    "#264478",
  ];

  async drawingChart(type: string) {
    const payload = await this.$store.dispatch("dashboard/getPayloadValue");
    //console.log(payload.startDate, payload.endDate);
    try {
      const result = await this.$store.dispatch("dashboard/getChartData", {
        ...payload,
      });
      let data;
      if (type === "sum") data = result.sumList;
      else if (type === "avg") data = result.avgList;
      if (data) {
        this.chartData.datasets = [];
        const chartData: Array<IChartDataSet> = [];
        let maxNumber = 0;
        data.forEach(
          (
            item: { date: number[]; list: { name: string; data: number[] } },
            index: number
          ) => {
            const listData = item.list.data;
            //console.log(listData);
            const time = listData.map((v) => {
              const hours = Math.floor(v / 60);
              const minute = v % 60;
              const time = hours + "." + minute;
              return Number(time);
            });
            //console.log(time);
            const max = Math.max.apply(null, time);
            if (maxNumber < max) {
              maxNumber = max;
            }
            if (index <= 10)
              chartData.push({
                label: item.list.name,
                backgroundColor: this.lineColor[index],
                borderColor: this.lineColor[index],
                data: listData,
                tension: 0.3,
              });
          }
        );

        const date = data[0].date.map((date: string) => {
          if (this.$store.state.dashboard.filterValue.searchPeriod === "1")
            return `${date.toString().slice(4, 6)}월 ${date
              .toString()
              .slice(6, 8)}일`;
          else if (
            this.$store.state.dashboard.filterValue.searchPeriod === "2" ||
            this.$store.state.dashboard.filterValue.searchPeriod === "3"
          ) {
            return date;
          }
        });
        this.chartData.labels = date;
        this.chartData.datasets = chartData;
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  }
  pdfDown() {
    html2canvas(this.$refs.chartCard.$el).then((canvas) => {
      const jspdf = new jsPDF("p", "mm");
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      jspdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      jspdf.save(`${this.date}_chart.pdf`);
    });
  }
}
</script>
