<template>
  <div>
    <v-card outlined>
      <v-card-title class="align-center justify-center font-weight-black">{{
        title
      }}</v-card-title>
      <v-card-subtitle class="text-end">
        {{ date }}
      </v-card-subtitle>
      <v-layout
        class="ma-10"
        align-center
        justify-center
        fill-height
        v-if="loading"
      >
        <v-progress-circular
          class="align-self-center"
          :size="70"
          :width="7"
          color="grey lighten-1"
          indeterminate
        ></v-progress-circular>
      </v-layout>
      <v-list v-else>
        <v-divider></v-divider>
        <v-list-item>
          <div class="ml-3 mr-3 font-weight-black">Rank</div>
          <v-list-item-avatar class="ml-5 mr-5">
            <v-img></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-black">App</v-list-item-title>
          </v-list-item-content>
          <div class="mr-3 font-weight-black">{{ headerLabel }}</div>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="item of tableData" :key="item.rank">
          <div class="ml-3 mr-3" style="min-width: 35px">{{ item.rank }}위</div>
          <v-list-item-avatar tile class="ml-5 mr-5">
            <v-icon v-if="item.icon === ''" x-large>mdi-cancel</v-icon>
            <v-img v-else :src="item.icon"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
          <div class="mr-2">{{ item.time }}{{ item.click }}{{ bodyLabel }}</div>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRankData } from "@/interfaces/dashboard/interface";

@Component({})
export default class RankTable extends Vue {
  @Prop() title?: string;
  @Prop() headerLabel?: string;
  @Prop() bodyLabel?: string;
  @Prop() date?: string;
  @Prop() loading?: boolean;
  @Prop() tableData?: Array<IRankData>;
}
</script>

<style scoped></style>
