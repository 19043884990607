<template>
  <v-app id="inspire">
    <v-main class="lighten-4">
      <!-- Login Component -->
      <v-container style="max-width: 450px" fill-height>
        <v-layout align-center row wrap>
          <v-flex xs12>
            <v-card>
              <div class="pa-10">
                <h2 style="text-align: center">Welcome</h2>
                <h1 style="text-align: center" class="mb-10">In APP</h1>
                <form>
                  <v-text-field
                    label="ID"
                    prepend-inner-icon="mdi-account"
                    color="info"
                    ref="loginId"
                    v-model="loginId"
                  ></v-text-field>
                  <v-text-field
                    prepend-inner-icon="mdi-lock"
                    type="password"
                    label="Password"
                    color="info"
                    v-model="loginPassword"
                  >
                  </v-text-field>
                  <v-card class="d-flex flex-row justify-center" flat tile>
                    <v-checkbox
                      class="mr-12"
                      label="아이디 저장"
                      color="info"
                      v-model="emailSave"
                      @click="passwordSave = false"
                    ></v-checkbox>
                    <v-checkbox
                      label="비밀번호 저장"
                      color="info"
                      v-model="passwordSave"
                      @click="emailSave = true"
                    ></v-checkbox>
                  </v-card>
                  <div class="text-center red--text">{{ LoginMsg }}</div>
                  <v-btn
                    color="blue lighten-1 text-capitalize"
                    depressed
                    large
                    block
                    dark
                    class="mb-3"
                    @click="loginValidate()"
                  >
                    로그인
                  </v-btn>
                  <v-btn
                    v-if="!isMobile"
                    @click="joinUser"
                    color="blue lighten-1 text-capitalize"
                    depressed
                    outlined
                    large
                    block
                    dark
                  >
                    회원가입
                  </v-btn>
                </form>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
        <v-dialog v-model="myDialog" width="400">
          <MyDialog @hide="hideDialog" :logo="dialogLogo">
            <template #body>
              <h3>{{ dialMsg }}</h3>
            </template>
            <template #btnname> 닫기 </template>
          </MyDialog>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import MyDialog from "../../components/MyDialog.vue";
import Component from "vue-class-component";
import router from "@/router";

declare global {
  interface Window {
    LogInView: {
      components: LogInView;
    };
  }
}

@Component({ components: { MyDialog } })
export default class LogInView extends Vue {
  private LoginMsg = "";
  private loginId = this.$store.state.loginId;
  private loginPassword = this.$store.state.loginPassword;
  private emailSave = false;
  private passwordSave = false;
  private myDialog = false;
  private dialogLogo = "";
  private dialMsg = "";
  private btnContent = "";
  private isMobile = /Mobi/i.test(window.navigator.userAgent);
  $refs!: {
    loginId: HTMLFormElement;
  };
  mounted() {
    this.emailSave = this.$store.state.loginIdSave;
    this.passwordSave = this.$store.state.loginPasswordSave;
    this.$refs.loginId.focus();
  }

  beforeMount() {
    window["LogInView"] = {
      components: this,
    };
  }

  async loginValidate() {
    //아이디, 패스워드 저장 체크 시 쿠키 저장

    this.loginId = this.loginId.replace(/ /g, "");
    try {
      const res = await this.$store.dispatch("loginValidate", {
        loginId: this.loginId,
        loginPassword: this.loginPassword,
      });
      if (!res.result) {
        this.showDialog(res.msg);
        this.loginPassword = "";
        return false;
      }

      if (this.emailSave) {
        this.$store.state.loginIdSave = true;
        this.$store.state.loginId = this.loginId;
      } else {
        this.$store.state.loginIdSave = false;
        this.$store.state.loginId = "";
      }
      if (this.passwordSave) {
        this.$store.state.loginPasswordSave = true;
        this.$store.state.loginPassword = this.loginPassword;
      } else {
        this.$store.state.loginPasswordSave = false;
        this.$store.state.loginPassword = "";
      }
      this.$store.state.userInfo.userId = this.loginId;
      if (this.isMobile) {
        window.JSBridge.showMessageInNative(
          this.loginId,
          this.loginPassword,
          this.emailSave,
          this.passwordSave,
          this.$store.state.token
        );
      }

      await router.push("/mypage/main");
    } catch (e) {
      console.log(e);
    }
  }
  async joinUser() {
    await this.$router.push("/join");
  }
  hideDialog() {
    this.myDialog = false;
  }
  showDialog(msg: string) {
    this.myDialog = true;
    this.dialogLogo = "Reload";
    this.btnContent = "닫기";
    this.dialMsg = msg;
  }
}
</script>

<style scoped></style>
