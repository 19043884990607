<template>
  <v-card tile outlined>
    <v-card-title v-if="title">{{ title }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-5" style="text-align: center">
      <div style="width: 100%; text-align: center">
        <img v-if="logo != null" :src="logoImg" width="50px" />
      </div>
      <slot name="body"> </slot>
    </v-card-text>
    <v-card-actions class="justify-center mr-2 pb-4">
      <v-btn color="#001F2B" dark tile small @click="hideEvent">
        <slot name="btnname"></slot>
      </v-btn>
      <v-btn
        color="primary"
        small
        tile
        @click="confirmEvent"
        v-show="isConfirm"
      >
        <slot name="confirmName"></slot>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class MyDialog extends Vue {
  @Prop() isConfirm?: boolean;
  @Prop() mode?: boolean;
  @Prop() logo?: string;
  @Prop() title?: string;
  private logoImg = "";

  mounted() {
    if (this.logo !== "")
      this.logoImg = require("/src/assets/" + this.logo + ".png");
  }
  @Watch("logo")
  setLogo() {
    if (this.logo !== "")
      this.logoImg = require("/src/assets/" + this.logo + ".png");
  }
  confirmEvent() {
    this.$emit("confirm");
  }

  hideEvent() {
    this.$emit("hide", this.mode);
  }
}
</script>

<style scoped></style>
