<template>
  <v-app>
    <SideNav></SideNav>
    <div style="height: 100vh">
      <custom-toolbar :toolbarTitle="toolbarTitle"></custom-toolbar>
      <v-container style="width: 95%">
        <v-btn
          outlined
          color="indigo"
          @click="showFilter"
          class="mb-3"
          style="width: 100px"
          v-show="!filterFlag"
        >
          <v-icon>mdi-format-list-bulleted-square</v-icon>
          필터
        </v-btn>
        <v-btn
          depressed
          color="indigo"
          dark
          @click="showFilter"
          class="mb-3"
          style="width: 100px"
          v-show="filterFlag"
        >
          <v-icon>mdi-format-list-bulleted-square</v-icon>
          필터 <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
        </v-btn>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="pageList"
            :search="search"
            :items-per-page="page.count"
            :fixed-header="true"
            :height="500"
            hide-default-footer
            v-model="selectItems"
            show-select
            item-key="no"
          >
            <template v-slot:[`item.userStatus`]="{ item }">
              <span v-html="getText(item.userStatus)"></span>
            </template>
            <template v-slot:[`item.dataStatus`]="{ item }">
              <span v-html="getText(item.dataStatus)"></span>
            </template>
            <template v-slot:[`item.lastDate`]="{ item }">
              <span v-html="getLastDate(item.lastDate)"></span>
            </template>
          </v-data-table>
        </v-card>
        <div class="d-flex mt-2">
          <div class="d-flex justify-start" style="width: 50%">
            <div class="text-center">
              <v-pagination
                v-model="page.current"
                :length="page.length"
                :total-visible="page.total"
                @input="movePage"
              ></v-pagination>
            </div>
          </div>
          <div class="d-flex justify-end" style="width: 50%">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div style="width: 300px">
                  <v-text-field
                    class="mr-2"
                    v-model="dates"
                    label="데이터 기간 설정"
                    single-line
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </div>
              </template>
              <v-date-picker
                color="primary"
                v-model="dates"
                no-title
                scrollable
                range
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-btn class="teal darken-3 mr-2" dark @click="excelDownFunc('app')"
              >APP<v-icon color="white darken-2">
                mdi-arrow-down-bold-box-outline
              </v-icon>
            </v-btn>
            <v-btn class="deep-purple" dark @click="excelDownFunc('profile')"
              >Profile<v-icon color="white darken-2">
                mdi-arrow-down-bold-box-outline
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="d-flex justify-end">
          <v-btn class="green darken-3" dark @click="showMessage">
            <v-icon color="white darken-2" left> mdi-comment-outline </v-icon>
            앱 삭제 메세지 전송
          </v-btn>
        </div>
        <div class="d-flex justify-end mt-2">
          <h5 style="color: #757575">
            ※ 다운로드 및 전송기능 사용을 위한 유저 선택은 필수 입니다.
          </h5>
        </div>
      </v-container>
      <v-dialog v-model="filter" width="350" persistent>
        <FilterDialog
          @hide="closeFilter"
          @apply="applyFilter"
          :filter="filter"
          ref="filter"
        >
          <template #btnname> 확인 </template>
          <template #closebtnname> 취소 </template>
        </FilterDialog>
      </v-dialog>
      <v-dialog v-model="messageDialog" width="350" persistent>
        <message-dialog @hide="closeMessage" :selected-items="selectItems">
          <template #btnname>전송</template>
          <template #closebtnname> 취소 </template>
        </message-dialog>
      </v-dialog>
      <v-dialog v-model="alertDialog" width="400" persistent>
        <MyDialog @hide="hideDialog" :logo="dialogLogo">
          <template #body>
            <h3>{{ dialMsg }}</h3>
          </template>
          <template #btnname> 확인 </template>
        </MyDialog>
      </v-dialog>
    </div>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import SideNav from "@/components/admin/SideNav.vue";
import CustomToolbar from "@/components/admin/CustomToolbar.vue";
import FilterDialog from "@/components/admin/FilterDialog.vue";
import MessageDialog from "@/components/admin/MessageDialog.vue";
import MyDialog from "@/components/MyDialog.vue";
import { IList } from "@/interfaces/interface";
import * as XLSX from "xlsx";
import { Watch } from "vue-property-decorator";
import moment from "moment";

moment.locale("ko");
@Component({
  components: { MessageDialog, SideNav, CustomToolbar, FilterDialog, MyDialog },
})
export default class AdminMonitoringView extends Vue {
  $refs!: {
    filter: HTMLFormElement;
  };
  private toolbarTitle = "Monitoring List";
  private page = {
    //페이지당 data 수
    count: 50,
    // 현재페이지
    current: 1,
    // 총 페이지 버튼 길이 초기화
    length: 0,
    // 보여줄 페이지 버튼의 수
    total: 5,
  };
  private filter = false;
  private filterFlag = false;
  private search = "";
  private selectItems: [] = [];
  private dates = [];
  private menu = false;
  private messageDialog = false;
  private alertDialog = false;
  private dialMsg = "";
  private dialogLogo = "";
  private filterOptions = {
    userStatus: ["NORMAL", "INSINCERITY", "WITHDRAW"],
    dataStatus: ["OK", "PAUSE", "STOP", "IMPOSSIBLE"],
  };
  private headers = [
    {
      text: "No.",
      align: "start",
      value: "no",
    },
    { text: "userID", value: "userId" },
    { text: "User Status", value: "userStatus" },
    { text: "Data Status", value: "dataStatus" },
    { text: "최근 전송 시간", value: "lastDate" },
  ];
  private pageList: Array<IList> = [];
  private list: Array<IList> = [];

  async mounted() {
    await this.getList();
    this.page.length = Math.ceil(this.list.length / this.page.count);
  }

  @Watch("dates")
  watchDatePicker() {
    const date1 = new Date(this.dates[0]).getTime();
    const date2 = new Date(this.dates[1]).getTime();
    if (date1 > date2) {
      this.dates = [];
    }
    const diff = Math.abs((date1 - date2) / (1000 * 60 * 60 * 24));
    if (diff > 6) {
      this.dates = [];
    }
  }

  async getList() {
    this.filter = false;
    try {
      this.list = await this.$store.dispatch("getList", this.filterOptions);
      for (let item of this.list) {
        switch (item.userStatus) {
          case "WITHDRAW":
            item.userStatus = "자진 탈퇴";
            break;
          case "INSINCERITY":
            item.userStatus = "불성실";
            break;
          case "NORMAL":
            item.userStatus = "정상";
            break;
        }
        switch (item.dataStatus) {
          case "OK":
            item.dataStatus = "정상 수집";
            break;

          case "PAUSE":
            item.dataStatus = "수집 중단";
            break;

          case "STOP":
            item.dataStatus = "수집 정지";
            break;

          case "IMPOSSIBLE":
            item.dataStatus = "수집 불가";
            break;
        }
      }
      this.movePage();
    } catch (e) {
      console.log(e);
    }
  }
  async applyFilter() {
    if (this.$refs.filter.toggle_dataStatus.length === 0)
      this.filterOptions.dataStatus = ["OK", "PAUSE", "STOP", "IMPOSSIBLE"];
    else this.filterOptions.dataStatus = this.$refs.filter.toggle_dataStatus;

    if (this.$refs.filter.toggle_userStatus.length === 0)
      this.filterOptions.userStatus = ["NORMAL", "INSINCERITY", "WITHDRAW"];
    else this.filterOptions.userStatus = this.$refs.filter.toggle_userStatus;

    this.filterFlag = !(
      this.$refs.filter.toggle_dataStatus.length === 0 &&
      this.$refs.filter.toggle_userStatus.length === 0
    );
    await this.getList();
  }

  showFilter() {
    this.filter = true;
  }
  closeFilter() {
    this.filter = false;
  }
  showMessage() {
    if (this.selectItems.length) this.messageDialog = true;
    else this.showDialog("메세지를 전송할 User를 선택해 주세요.");
  }
  closeMessage() {
    this.messageDialog = false;
  }
  showDialog(msg: string) {
    this.alertDialog = true;
    this.dialogLogo = "ExclamationMark";
    this.dialMsg = msg;
  }
  hideDialog() {
    this.alertDialog = false;
  }

  getLastDate(date: string | null) {
    return date === null
      ? null
      : moment(date).format("YYYY년 MMMM Do, a h:mm:ss");
  }
  getText(text: string) {
    switch (text) {
      case "수집 불가":
        return "<span style='color:red'>수집 불가상태</span>";

      case "수집 정지":
        return "<span>수집 정지상태</span>";

      case "수집 중단":
        return "<span>수집 중단상태</span>";

      case "정상 수집":
        return "<span style='color:steelblue'>정상 수집상태</span>";

      case "정상":
        return "<span style='color:steelblue'>정상</span>";

      case "불성실":
        return "<span>불성실</span>";

      case "자진 탈퇴":
        return "<span>자진 탈퇴</span>";
    }
  }

  async excelDownFunc(type: string) {
    let users: string[] = [];
    this.selectItems.forEach((item: any) => {
      users.push(item.userId);
    });
    if (type === "app") {
      if (this.selectItems.length) {
        if (this.dates.length === 2) {
          const list = await this.$store.dispatch("getAppList", {
            dates: this.dates,
            users,
          });
          if (list) {
            const dataWS = XLSX.utils.json_to_sheet(list);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, "nameData");
            XLSX.writeFile(wb, `App_${new Date().getTime()}.xlsx`);
          } else {
            this.showDialog("일치하는 데이터가 없습니다.");
            return false;
          }
        } else {
          this.showDialog("데이터를 다운받을 기간을 선택해 주세요.");
          return false;
        }
      } else {
        this.showDialog("데이터를 다운받을 User를 선택해 주세요.");
        return false;
      }
    }
    if (type === "profile") {
      if (this.selectItems.length) {
        const list = await this.$store.dispatch("getProfileList", { users });
        list.forEach((item: any) => {
          item.userInterest = item.userInterest.toString();
        });
        const dataWS = XLSX.utils.json_to_sheet(list);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataWS, "nameData");
        XLSX.writeFile(wb, `profile_${new Date().getTime()}.xlsx`);
      } else this.showDialog("데이터를 다운받을 User를 선택해 주세요.");
    }
  }

  //1. 모든 데이터 가져온 후 처리
  movePage() {
    //((current-1)*4)+1 부터 currnet*4 까지
    this.selectItems = []; //전 페이지 체크 풀기
    this.pageList = [];
    for (
      let i = (this.page.current - 1) * this.page.count + 1;
      i <= this.page.current * this.page.count;
      i++
    ) {
      if (i > this.list.length) {
        break;
      }
      this.list[i - 1].no = i;
      this.pageList.push(this.list[i - 1]);
    }
  }
}
</script>

<style scoped></style>
