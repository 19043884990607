import { Module } from "vuex";
import { State } from "@/store";
import axios from "@/plugins/axios";
import { IFilterValue, IPayload } from "@/interfaces/dashboard/interface";
import moment from "moment";

interface Dashboard {
  filterValue: {
    selectedGender: Array<{ no: number; text: string }>;
    selectedAddress: Array<{ no: number; text: string }>;
    selectedInterest: Array<{ no: number; text: string }>;
    selectedAge: Array<{ no: number; text: string }>;
    selectedFamily: Array<{ no: number; text: string }>;
    selectedMarried: Array<{ no: number; text: string }>;
    selectedLifeStage: Array<{ no: number; text: string }>;
    dates: string[];
    month: string;
    searchPeriod: string;
    category: string[];
  };
  dateLabel: string[];
}

const dashboard: Module<Dashboard, State> = {
  namespaced: true,
  state: {
    filterValue: {
      selectedGender: [],
      selectedAddress: [],
      selectedInterest: [],
      selectedAge: [],
      selectedFamily: [],
      selectedMarried: [],
      selectedLifeStage: [],
      dates: [],
      month: "",
      searchPeriod: "1",
      category: [],
    },
    dateLabel: [],
  },
  getters: {},
  mutations: {
    setFilerValue(state: Dashboard, payload: IFilterValue) {
      state.filterValue.selectedGender = payload.selectedGender;
      state.filterValue.selectedAddress = payload.selectedAddress;
      state.filterValue.selectedInterest = payload.selectedInterest;
      state.filterValue.selectedAge = payload.selectedAge;
      state.filterValue.selectedFamily = payload.selectedFamily;
      state.filterValue.selectedMarried = payload.selectedMarried;
      state.filterValue.selectedLifeStage = payload.selectedLifeStage;
      state.filterValue.dates = payload.dates;
      state.filterValue.month = payload.month;
      state.filterValue.searchPeriod = payload.searchPeriod;
      state.filterValue.category = payload.category;
    },
    initFilterValue(state: Dashboard) {
      state.filterValue.selectedGender = [];
      state.filterValue.selectedAddress = [];
      state.filterValue.selectedInterest = [];
      state.filterValue.selectedAge = [];
      state.filterValue.selectedFamily = [];
      state.filterValue.selectedMarried = [];
      state.filterValue.selectedLifeStage = [];
      state.filterValue.searchPeriod = "1";
      state.filterValue.dates = [];
      state.filterValue.month = "";
      state.filterValue.category = [];
    },
  },
  actions: {
    async checkData(context, payload: IPayload) {
      const res = await axios.get("boards/data", {
        params: {
          ...payload,
        },
      });
      return res.data.result;
    },
    async getChartData({ state }, payload: IPayload) {
      try {
        const res = await axios.get("boards/chart", {
          params: {
            ...payload,
          },
        });
        const { firstDate, lastDate, sumList, avgList } = res.data;
        if (sumList.length > 0) {
          state.dateLabel[0] = firstDate;
          state.dateLabel[1] = lastDate;
          return { sumList, avgList };
        } else {
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getLastDate() {
      const res = await axios.get("boards/last-day", {});
      return res.data;
    },
    async getCategoryList() {
      const res = await axios.get("boards/category", {});
      return res.data;
    },
    async getExcelData(context, payload: IPayload) {
      try {
        const res = await axios.get("boards/excel", {
          params: {
            ...payload,
          },
        });
        return res.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getRankData(context, payload: IPayload) {
      try {
        const res = await axios.get("boards/rank", {
          params: {
            ...payload,
          },
        });
        //console.log(res.data);
        return res.data;
      } catch (e) {
        console.log(e);
      }
    },
    getPayloadValue({ state }, filterValue: IFilterValue) {
      const payload: IPayload = {
        userGender: [],
        userAddress: [],
        userInterest: [],
        userAge: [],
        userFamily: [],
        userMarried: [],
        userLifeStage: [],
        searchPeriod: 1,
        startDate: "",
        endDate: "",
        category: [],
      };
      let selectedGender = [];
      let selectedAddress = [];
      let selectedInterest = [];
      let selectedAge = [];
      let selectedFamily = [];
      let selectedMarried = [];
      let selectedLifeStage = [];
      let searchPeriod = "";
      let dates = [];
      let month = "";
      let category = [];

      if (filterValue) {
        selectedGender = filterValue.selectedGender;
        selectedAddress = filterValue.selectedAddress;
        selectedInterest = filterValue.selectedInterest;
        selectedAge = filterValue.selectedAge;
        selectedFamily = filterValue.selectedFamily;
        selectedMarried = filterValue.selectedMarried;
        selectedLifeStage = filterValue.selectedLifeStage;
        searchPeriod = filterValue.searchPeriod;
        dates = filterValue.dates;
        month = filterValue.month;
        category = filterValue.category;
      } else {
        selectedGender = state.filterValue.selectedGender;
        selectedAddress = state.filterValue.selectedAddress;
        selectedInterest = state.filterValue.selectedInterest;
        selectedAge = state.filterValue.selectedAge;
        selectedFamily = state.filterValue.selectedFamily;
        selectedMarried = state.filterValue.selectedMarried;
        selectedLifeStage = state.filterValue.selectedLifeStage;
        searchPeriod = state.filterValue.searchPeriod;
        dates = state.filterValue.dates;
        month = state.filterValue.month;
        category = state.filterValue.category;
      }

      payload.searchPeriod = Number(searchPeriod);
      payload.category = category;
      if (payload.searchPeriod === 1) {
        payload.startDate = dates[0];
        payload.endDate = dates[1];
      } else if (payload.searchPeriod === 2) {
        payload.startDate = month;
        payload.endDate = month;
      } else if (payload.searchPeriod === 3) {
        const endMonth = new Date(month);
        endMonth.setMonth(endMonth.getMonth() + 11);
        payload.startDate = month;
        payload.endDate = moment(endMonth).format("YYYY-MM");
      }

      if (selectedGender.length) {
        const values = selectedGender.filter((value) => value.no != 0);
        payload["userGender"] = values.map((value: { no: number }) => {
          return value.no;
        });
      }
      if (selectedAddress.length) {
        const values = selectedAddress.filter((value) => value.no != 0);
        payload["userAddress"] = values.map((value: { text: string }) => {
          return value.text;
        });
      }
      if (selectedInterest.length) {
        const values = selectedInterest.filter((value) => value.no != 0);
        payload["userInterest"] = values.map((value: { no: number }) => {
          return value.no;
        });
      }
      if (selectedAge.length) {
        const values = selectedAge.filter((value) => value.no != 0);
        payload["userAge"] = values.map((value: { no: number }) => {
          return value.no;
        });
      }

      if (selectedFamily.length) {
        const values = selectedFamily.filter((value) => value.no != 0);
        payload["userFamily"] = values.map((value: { no: number }) => {
          return value.no;
        });
      }

      if (selectedMarried.length) {
        const values = selectedMarried.filter((value) => value.no != 0);
        payload["userMarried"] = values.map((value: { no: number }) => {
          return value.no;
        });
      }

      if (selectedLifeStage.length) {
        const values = selectedLifeStage.filter((value) => value.no != 0);
        payload["userLifeStage"] = values.map((value: { no: number }) => {
          return value.no;
        });
      }
      return payload;
    },
  },
};

export default dashboard;
