<template>
  <div>
    <a
      v-if="options && options.tab && options.anchor"
      @click="goToMain"
      target="_blank"
      :class="{ 'detail-a': detailMenu }"
    >
      <span v-if="title === '로그아웃'" @click="logout">
        {{ title }}
      </span>
      <span v-else @click="clickMenu(title)">
        {{ title }}
      </span>
    </a>

    <a
      v-else-if="options && !options.tab && options.anchor"
      @click="goToMain"
      :class="{ 'detail-a': detailMenu }"
    >
      <span v-if="title === '로그아웃'" @click="logout">
        {{ title }}
      </span>
      <span v-else @click="clickMenu(title)">
        {{ title }}
      </span>
    </a>

    <router-link
      v-else-if="options && options.tab"
      :to="url"
      target="_blank"
      :class="{ 'detail-a': detailMenu }"
    >
      <span v-if="title === '로그아웃'" @click="logout">
        {{ title }}
      </span>
      <span v-else @click="clickMenu(title)">
        {{ title }}
      </span>
    </router-link>

    <router-link v-else :to="url" :class="{ 'detail-a': detailMenu }">
      <span v-if="title === '로그아웃'" @click="logout">
        {{ title }}
      </span>
      <span v-else @click="clickMenu(title)">
        {{ title }}
      </span>
    </router-link>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class BasicMenu extends Vue {
  @Prop() title?: string;
  @Prop() url?: string;
  @Prop() options?: { anchor?: boolean; tab?: boolean };
  @Prop() detailMenu?: boolean;

  @Emit("logout")
  private logout() {
    return;
  }
  @Emit("clickMenu")
  private clickMenu(title: string) {
    return title;
  }
  goToMain() {
    console.log(this.url);
    if (this.url) document.location.href = this.url;
  }
}
</script>
<style scoped>
a {
  color: inherit !important;
}
.detail-a {
  line-height: 14px;
  color: #414141 !important;
  transition: color 0.1s;
}

.detail-a:hover {
  font-weight: bold;
}
</style>
