<template>
  <nav>
    <div class="nav-container">
      <img :src="imgSrc" id="logo" alt="logoImage" style="margin-top: 10px" />
      <div
        class="title-container"
        @mouseenter="hover(true)"
        @mouseleave="hover(false)"
      >
        <div v-if="mainTitle">
          <BasicMenu
            :title="mainTitle.title"
            :url="mainTitle.url"
            :options="mainTitle.options"
            @clickMenu="clickMenu"
            @logout="logout"
          />
        </div>
      </div>
      <div
        class="main-menu-container"
        @mouseenter="hover(true)"
        @mouseleave="hover(false)"
      >
        <div v-if="mainMenu" style="justify-content: space-between">
          <div
            v-for="{ title, url, options, detailMenu } in getMenu(mainMenu)"
            :key="`key-${title}`"
            class="main-menu-title-container"
            :style="
              hoveredMenu === title ? { borderBottom: 'solid 3px #00a880' } : {}
            "
            @mouseenter="setHoveredMenu(title)"
          >
            <div v-if="options && options.tab && options.anchor">
              <div class="main-title-layout">
                <a :href="url" target="_blank">
                  <span v-if="title === '로그아웃'" @click="logout">
                    {{ title }}
                  </span>
                  <span v-else @click="clickMenu(title)">
                    {{ title }}
                  </span>
                </a>
              </div>
              <ul
                class="detail-list-ul"
                v-if="detailMenu"
                :class="{ 'detail-list-ul-show': hoverCheck }"
                :style="getListWidth()"
                ref="listUl"
              >
                <li
                  v-for="{
                    title: detailTitle,
                    url: detailUrl,
                    options: detailOptions,
                  } in detailMenu"
                  :key="detailTitle"
                  class="detail-list-li"
                  :class="{ 'detail-list-li-show': hoverCheck }"
                >
                  <BasicMenu
                    :title="detailTitle"
                    :url="detailUrl"
                    :options="detailOptions"
                    :detailMenu="true"
                    @clickMenu="clickMenu"
                    @logout="logout"
                  />
                </li>
              </ul>
            </div>

            <div v-else-if="options && !options.tab && options.anchor">
              <div class="main-title-layout">
                <a :href="url">
                  <span v-if="title === '로그아웃'" @click="logout">
                    {{ title }}
                  </span>
                  <span v-else @click="clickMenu(title)">
                    {{ title }}
                  </span>
                </a>
              </div>
              <ul
                class="detail-list-ul"
                v-if="detailMenu"
                :class="{ 'detail-list-ul-show': hoverCheck }"
                :style="getListWidth()"
                ref="listUl"
              >
                <li
                  v-for="{
                    title: detailTitle,
                    url: detailUrl,
                    options: detailOptions,
                  } in detailMenu"
                  :key="detailTitle"
                  class="detail-list-li"
                  :class="{ 'detail-list-li-show': hoverCheck }"
                >
                  <BasicMenu
                    :title="detailTitle"
                    :url="detailUrl"
                    :options="detailOptions"
                    :detailMenu="true"
                    @clickMenu="clickMenu"
                    @logout="logout"
                  />
                </li>
              </ul>
            </div>

            <div v-else-if="options && options.tab">
              <div class="main-title-layout">
                <router-link :to="url" target="_blank">
                  <span v-if="title === '로그아웃'" @click="logout">
                    {{ title }}
                  </span>
                  <span v-else @click="clickMenu(title)">
                    {{ title }}
                  </span>
                </router-link>
              </div>
              <ul
                class="detail-list-ul"
                v-if="detailMenu"
                :class="{ 'detail-list-ul-show': hoverCheck }"
                :style="getListWidth()"
                ref="listUl"
              >
                <li
                  v-for="{
                    title: detailTitle,
                    url: detailUrl,
                    options: detailOptions,
                  } in detailMenu"
                  :key="detailTitle"
                  class="detail-list-li"
                  :class="{ 'detail-list-li-show': hoverCheck }"
                >
                  <BasicMenu
                    :title="detailTitle"
                    :url="detailUrl"
                    :options="detailOptions"
                    :detailMenu="true"
                    @clickMenu="clickMenu"
                    @logout="logout"
                  />
                </li>
              </ul>
            </div>

            <div v-else>
              <div class="main-title-layout">
                <router-link v-if="url" :to="url">
                  <span v-if="title === '로그아웃'" @click="logout">
                    {{ title }}
                  </span>
                  <span v-else @click="clickMenu(title)"> {{ title }}</span>
                </router-link>
                <div v-else style="cursor: pointer">
                  <span v-if="title === '로그아웃'" @click="logout">
                    {{ title }}
                  </span>
                  <span v-else @click="clickMenu(title)"> {{ title }} </span>
                </div>
              </div>
              <ul
                class="detail-list-ul"
                v-if="detailMenu"
                :class="{ 'detail-list-ul-show': hoverCheck }"
                :style="getListWidth()"
                ref="listUl"
              >
                <li
                  v-for="{
                    title: detailTitle,
                    url: detailUrl,
                    options: detailOptions,
                  } in detailMenu"
                  :key="detailTitle"
                  class="detail-list-li"
                  :class="{ 'detail-list-li-show': hoverCheck }"
                >
                  <BasicMenu
                    :title="detailTitle"
                    :url="detailUrl"
                    :options="detailOptions"
                    :detailMenu="true"
                    @clickMenu="clickMenu"
                    @logout="logout"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-menu-container">
        <div v-if="subMenu">
          <div
            v-for="{ title, url, options } in getMenu(subMenu)"
            :key="`key-${title}`"
          >
            <BasicMenu
              :title="title"
              :url="url"
              :options="options"
              @clickMenu="clickMenu"
              @logout="logout"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="detail-list-bg"
      :style="hoverCheck ? { height: `${detailBgHeight}px` } : {}"
      @mouseenter="hover(true)"
      @mouseleave="hover(false)"
    >
      <div
        class="detail-list-inner-bg"
        :style="hoverCheck ? { height: `${detailBgHeight}px` } : {}"
      >
        <div
          class="detail-list-inner-text"
          :class="{ 'detail-list-ul-show': hoverCheck }"
        >
          <div class="detail-list-inner-title-text">통합 데이터 허브</div>
          <div class="detail-list-inner-sub-text">
            이종 데이터 통합 분석 서비스<br />(저장/분석/시각화)를 이용할 수
            있는<br />플랫폼 입니다.
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import BasicMenu from "@/components/dashboard/BasicMenu.vue";
export interface IBasicMenu {
  title: string;
  url?: string;
  options?: {
    anchor?: boolean;
    tab?: boolean;
  };
}

export interface IMainMenu {
  title: string;
  url?: string;
  detailMenu?: Array<IBasicMenu>;
  options?: {
    anchor?: boolean;
    tab?: boolean;
  };
}

@Component({
  components: { BasicMenu },
})
export default class Nav2 extends Vue {
  @Prop() mainTitle?: IBasicMenu;
  @Prop() mainMenu?: IMainMenu[];
  @Prop() subMenu?: IBasicMenu[];
  @Prop() imgSrc?: any;
  @Prop() isLogin?: boolean;

  @Emit("clickMenu")
  private clickMenu(title: string) {
    return title;
  }
  @Emit("logout")
  private logout() {
    return;
  }

  private hoverCheck = false;
  private detailBgHeight = 0;
  private hoveredMenu = "";

  public hover(check: boolean) {
    if (!check) this.hoveredMenu = "";
    this.hoverCheck = check;
  }

  private setHoveredMenu(title: string) {
    this.hoveredMenu = title;
  }

  private getListWidth() {
    if (this.mainMenu) return { width: `${620 / this.mainMenu.length}px` };
  }

  private getMenu(menu: IBasicMenu[] | IMainMenu[]) {
    if (this.isLogin) {
      const currentMenu = menu.filter((item) => item.title !== "회원가입");
      const loginMenu = currentMenu.find((item) => item.title === "로그인");
      if (loginMenu) loginMenu.title = "로그아웃";
      return currentMenu;
    } else if (!this.isLogin) {
      const currentMenu = menu.filter((item) => item.title !== "마이페이지");
      const loginMenu = currentMenu.find((item) => item.title === "로그아웃");
      if (loginMenu) loginMenu.title = "로그인";
      return currentMenu;
    }
    return menu;
  }

  mounted() {
    const ul = this.$refs.listUl as HTMLUListElement[];
    if (ul && Array.isArray(ul)) {
      ul.forEach((li) => {
        if (this.detailBgHeight < li.clientHeight) this.detailBgHeight = 160;
      });
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,700,500");
@font-face {
  font-family: "Pretendard-Medium-Nav";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff")
    format("woff");
}

a {
  color: inherit !important;
}

#logo {
  width: 34px;
  height: 34px;
  object-fit: contain;
}

.nav-container {
  border-bottom: #adafba 1px solid;
  min-width: 1903px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*padding: 0px 390px 0px; 1920화면에서 스크롤바 때문에 넓이가 1903임 때문에 패딩 390이 있어 크기가 달라져 주석*/
  transition: background-color 0.3s;
  background-color: #ffffff;
}

.title-container {
  color: #000000;
  font-family: "Pretendard" !important;
  font-size: 20px !important;
  font-weight: 600;
  letter-spacing: -1.61px;
  width: 230px;
  padding-top: 10px;
  padding-left: 5px;
  height: 100%;
  display: flex;
  align-items: center;
}

.main-menu-container {
  font-family: "Pretendard-Medium-Main" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  width: 620px;
  height: 84px;
}

.main-menu-container > div {
  display: flex;
  height: 100%;
}

.main-menu-title-container {
  width: 22%;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.main-menu-title-container > div {
  padding-left: 8px;
  color: #000000;
  width: 100%;
}

.main-title-layout {
  display: flex;
  justify-content: start;
}

.detail-list-bg {
  width: 100%;
  position: absolute;
  top: 84px;
  height: 0;
  z-index: 1000;
  background: #ffffff;
  transition: all 0.5s;
}
.detail-list-inner-bg {
  position: absolute;
  height: 0;
  background: rgba(0, 168, 128, 0.15);
  transition: all 0.5s;
  display: flex;
  justify-content: end;
  padding: 0px 40px 0px 0px;
}
@media (max-width: 2100px) {
  .detail-list-inner-bg {
    width: 640px;
  }
}
@media (min-width: 2101px) and (max-width: 2200px) {
  .detail-list-inner-bg {
    width: 700px;
  }
}
@media (min-width: 2201px) and (max-width: 2300px) {
  .detail-list-inner-bg {
    width: 760px;
  }
}
@media (min-width: 2301px) and (max-width: 2400px) {
  .detail-list-inner-bg {
    width: 820px;
  }
}
@media (min-width: 2401px) and (max-width: 2550px) {
  .detail-list-inner-bg {
    width: 880px;
  }
}
@media (min-width: 2551px) {
  .detail-list-inner-bg {
    width: 940px;
  }
}

.detail-list-inner-text {
  margin-top: 20px;
  visibility: hidden;
  line-height: 18px;
  opacity: 0;
}

.detail-list-inner-title-text {
  color: #00a880;
  font-size: 18px;
  font-family: "Pretendard-SemiBold-Main";
}
.detail-list-inner-sub-text {
  color: #2b2b2b;
  font-size: 14px;
  font-family: "Pretendard-Regular-Main";
  line-height: 17px;
  margin-top: 20px;
}
.detail-list-ul {
  position: absolute;
  display: grid;
  align-content: start;
  top: 84px;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  padding: 25px 0;
}

.detail-list-li {
  font-family: "Pretendard-Regular-Main";
  font-size: 14px;
  visibility: hidden;
  cursor: pointer;
  padding-bottom: 15px;
  opacity: 0;
}

.detail-list-li:last-child {
  padding-bottom: 0;
}

.detail-list-ul-show,
.detail-list-li-show {
  opacity: 1;
  visibility: visible;
  animation-duration: 0.5s;
  animation-name: fade;
}

.sub-menu-container {
  color: #000000;
  font-family: "Noto Sans KR" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  width: 260px;
  margin-top: 10px;
  line-height: 14px;
}

.sub-menu-container > div {
  display: flex;
  justify-content: flex-end;
  gap: 13px;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
</style>
