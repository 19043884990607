<template>
  <div>
    <v-card>
      <v-card-title class="justify-center">필터</v-card-title>
      <hr />
      <v-card-text class="pa-5">
        <div>
          <div class="d-flex">
            <h4>User Status</h4>
          </div>
          <div class="d-flex justify-space-around mb-3">
            <v-btn-toggle v-model="toggle_userStatus_temp" multiple>
              <v-btn color="primary" dark outlined width="90" value="NORMAL">
                정상
              </v-btn>
              <v-btn
                color="primary"
                dark
                outlined
                width="90"
                value="INSINCERITY"
              >
                불성실
              </v-btn>
              <v-btn color="primary" dark outlined width="90" value="WITHDRAW">
                자진탈퇴
              </v-btn>
            </v-btn-toggle>
          </div>
          <div>
            <div class="d-flex">
              <h4>Data Status</h4>
            </div>
            <div class="d-flex justify-space-around mb-2">
              <v-btn-toggle v-model="toggle_dataStatus_temp" multiple>
                <v-btn color="primary" dark outlined width="130" value="OK">
                  정상 수집
                </v-btn>
                <v-btn color="primary" dark outlined width="130" value="PAUSE">
                  수집 중단
                </v-btn>
              </v-btn-toggle>
            </div>
            <div class="d-flex justify-space-around mb-6">
              <v-btn-toggle v-model="toggle_dataStatus_temp" multiple>
                <v-btn color="primary" dark outlined width="130" value="STOP">
                  수집 정지
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  outlined
                  width="130"
                  value="IMPOSSIBLE"
                >
                  수집 불가
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center mr-2 pb-4" style="width: 100%">
        <v-btn color="primary" dark rounded @click="apply" style="width: 40%">
          <slot name="btnname"></slot>
        </v-btn>
        <v-btn
          color="grey darken-2"
          dark
          rounded
          @click="closeSelf"
          style="width: 40%"
        >
          <slot name="closebtnname"></slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import MyDialog from "@/components/MyDialog.vue";

@Component({ components: { MyDialog } })
export default class FilterDialog extends Vue {
  @Prop() filter?: boolean;

  private toggle_userStatus = [];
  private toggle_dataStatus = [];

  private toggle_userStatus_temp = [];
  private toggle_dataStatus_temp = [];

  @Watch("filter")
  test() {
    this.toggle_dataStatus_temp = this.toggle_dataStatus;
    this.toggle_userStatus_temp = this.toggle_userStatus;
  }

  apply() {
    this.toggle_dataStatus = this.toggle_dataStatus_temp;
    this.toggle_userStatus = this.toggle_userStatus_temp;
    this.$emit("apply");
  }
  closeSelf() {
    this.$emit("hide");
  }
}
</script>

<style scoped></style>
