<template>
  <div style="height: 100vh">
    <v-container class="pa-0 col-md-4 col-lg-3 col-xl-3" fill-height>
      <v-card
        class="mx-auto"
        align-center
        min-width="100%"
        min-height="100vh"
        tile
      >
        <v-main align-center>
          <v-toolbar flat color="info" dark>
            <v-toolbar-title class="font-weight-bold">
              Profile 변경
            </v-toolbar-title>
          </v-toolbar>
          <div class="pa-5">
            <Profile ref="profile"></Profile>

            <h4>핸드폰 번호</h4>
            <div class="d-flex justify-md-space-between mb-3">
              <v-text-field
                v-model="userInfo.userPhoneNumber"
                :readonly="isNumberChanged"
                type="number"
                ref="number"
                maxlength="11"
                oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                label="휴대폰 번호"
                hint="( - 없이 입력)"
                persistent-hint
              >
              </v-text-field>
              <v-btn
                depressed
                color="grey darken-2"
                :disabled="numberValid"
                class="ma-0"
                small
                style="color: white"
                @click="PhoneNumberAuth"
              >
                인증하기
              </v-btn>
            </div>
            <div class="d-flex justify-space-between">
              <v-btn width="50%" rounded color="info" @click="formValidator">
                변경하기
              </v-btn>
              <v-btn
                width="50%"
                rounded
                dark
                color="grey lighten-1"
                to="/mypage/main"
              >
                취소
              </v-btn>
            </div>
          </div>
        </v-main>
      </v-card>
    </v-container>
    <v-dialog v-model="phoneDialog" width="400" persistent>
      <PhoneAuthDialog
        @hide="hidePhoneDialog"
        @modalclose="authModalClose"
        :btn-content="this.userInfo.userPhoneNumber"
        :active="phoneDialog"
      >
        <template #body>
          <h3>{{ dialMsg }}</h3>
        </template>
        <template #btnname> 인증하기 </template>
        <template #closebtnname> 취소 </template>
      </PhoneAuthDialog>
    </v-dialog>
    <v-dialog v-model="alertDialog" width="500" persistent>
      <MyDialog @hide="hideDialog" :mode="alertMode" :logo="dialogLogo">
        <template #body>
          <h3>{{ dialMsg }}</h3>
        </template>
        <template #btnname> 닫기 </template>
      </MyDialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import Profile from "../../components/Profile.vue";
import MyDialog from "@/components/MyDialog.vue";
import PhoneAuthDialog from "@/components/PhoneAuthDialog.vue";
///////////////////////////////유효성검증 작업 해야함
@Component({ components: { Profile, MyDialog, PhoneAuthDialog } })
export default class ModifyProfileView extends Vue {
  $refs!: {
    profile: HTMLFormElement;
    number: HTMLFormElement;
  };

  private userInfo = {
    userId: this.$store.state.userInfo.userId,
    userPhoneNumber: "",
    userGender: 0,
    userBirthDate: "",
    userAddress: null,
    userMarried: 0,
    userInterest: [],
    userFamily: 0,
    userLifeStage: 0,
  };
  private alertMode = true;
  private alertDialog = false;
  private btnContent = "";
  private dialMsg = "";
  private dialogLogo = "";
  private phoneDialog = false;
  private isNumberChanged = false;
  private numberValid = true;
  private beforePhoneNumber = "";

  async mounted() {
    //회원정보 불러오기

    const res = await this.$store.dispatch("getUserInfo", {
      userId: this.userInfo.userId,
    });
    this.$refs.profile.userInfo.userGender = res.userGender.toString();
    this.$refs.profile.userInfo.userBirthDate = res.userBirthDate;
    this.$refs.profile.userInfo.userAddress = res.userAddress;
    this.$refs.profile.userInfo.userMarried = res.userMarried.toString();
    this.$refs.profile.userInfo.userInterest = res.userInterest;
    this.$refs.profile.userInfo.userFamily = res.userFamily.toString();
    this.$refs.profile.userInfo.userLifeStage = res.userLifeStage.toString();
    this.userInfo.userPhoneNumber = res.userPhoneNumber.toString();
    this.beforePhoneNumber = res.userPhoneNumber.toString();
  }
  @Watch("userInfo.userPhoneNumber")
  changeNumber() {
    if (this.beforePhoneNumber !== this.userInfo.userPhoneNumber)
      this.numberValid = false;
  }
  async formValidator() {
    this.userInfo.userGender = Number(this.$refs.profile.userInfo.userGender);
    this.userInfo.userBirthDate = this.$refs.profile.userInfo.userBirthDate;
    this.userInfo.userAddress = this.$refs.profile.userInfo.userAddress;
    this.userInfo.userMarried = Number(this.$refs.profile.userInfo.userMarried);
    this.userInfo.userInterest = this.$refs.profile.userInfo.userInterest;
    this.userInfo.userFamily = Number(this.$refs.profile.userInfo.userFamily);
    this.userInfo.userLifeStage = Number(
      this.$refs.profile.userInfo.userLifeStage
    );
    if (this.numberValid) {
      const result = await this.$store.dispatch("modifyProfile", this.userInfo);
      if (result) {
        this.showDialog(result, false, "X");
      } else this.showDialog("등록되었습니다.", true, "Check");
    } else {
      this.showDialog("휴대폰 인증이 필요합니다.", false, "X");
    }
  }
  hideDialog(mode: boolean) {
    this.alertDialog = false;
    if (mode) this.$router.push("/mypage/main");
  }
  showDialog(msg: string, mode: boolean, logo: string) {
    this.alertDialog = true;
    this.btnContent = "닫기";
    this.dialogLogo = logo;
    this.alertMode = mode;
    this.dialMsg = msg;
  }
  async PhoneNumberAuth() {
    if (this.userInfo.userPhoneNumber.length !== 11) {
      this.showDialog("유효하지 않는 핸드폰 번호 입니다.", false, "X");
      this.$refs.number.focus();
      return false;
    }
    try {
      let res = await this.$store.dispatch("checkPhoneNumber", {
        userPhoneNumber: this.userInfo.userPhoneNumber,
      });
      if (!res.result) {
        this.showDialog(res.msg, false, "X");
        this.numberValid = false;
        return false;
      }
      this.phoneDialog = true;
      this.dialMsg = this.userInfo.userPhoneNumber;
      const randomNumber: string = Math.floor(Math.random() * 1000000)
        .toString()
        .padStart(6, "0");
      const message =
        "본인확인 인증번호(" + randomNumber + ")를 입력해 주세요.";
      res = await this.$store.dispatch("sendMessage", {
        messageContent: message,
        randomNumber: randomNumber,
        receiver: [
          {
            mobile: this.userInfo.userPhoneNumber,
          },
        ],
        isAuth: true,
      });
      if (!res.result) {
        this.hidePhoneDialog();
        this.showDialog("발송 실패 ERROR CODE : " + res.status, false, "X");
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  authModalClose(type: string) {
    if (type === "phone") {
      this.phoneDialog = false;
      this.numberValid = true;
      this.isNumberChanged = true;
    }
  }
  hidePhoneDialog() {
    this.phoneDialog = false;
  }
}
</script>

<style scoped></style>
