<template>
  <div>
    <v-card>
      <v-card-title class="justify-center">휴대폰 인증</v-card-title>
      <hr />
      <v-card-text class="pa-5">
        <div class="text-center">
          아래의 번호로 인증 문자가 발송되었습니다!<br />
          확인 후 아래에 인증코드를 입력해주세요.<br />
          <slot name="body"> </slot>
          <v-btn small outlined @click="reSendCode">
            <b>재전송</b>
          </v-btn>
          <br />
          <br />
          인증코드 입력
          <v-text-field
            v-model="code"
            maxlength="6"
            label="인증 코드"
            color="info"
            persistent-hint
            outlined
            :suffix="getTime"
          >
          </v-text-field>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center mr-2 pb-4">
        <v-btn color="info" dark @click="codeValidation">
          <slot name="btnname"></slot>
        </v-btn>
        <v-btn color="grey darken-2" dark @click="closeSelf">
          <slot name="closebtnname"></slot>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="alertDialog" width="500" persistent>
      <MyDialog @hide="hideDialog" :logo="dialogLogo">
        <template #body>
          <h3>{{ dialMsg }}</h3>
        </template>
        <template #btnname> 닫기 </template>
      </MyDialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import MyDialog from "@/components/MyDialog.vue";
import { Prop, Vue, Component, Watch } from "vue-property-decorator";

@Component({ components: { MyDialog } })
export default class PhoneAuthDialog extends Vue {
  @Prop() btnContent!: string;
  @Prop() active!: boolean;

  private alertDialog = false;
  private dialMsg = "";
  private dialogLogo = "";
  private code = "";
  private setTime = 5 * 60;
  private time = 0;
  private timer = 0;
  private flag = false;

  mounted() {
    this.flag = this.active;
  }

  @Watch("flag")
  startTimer() {
    if (this.flag) {
      this.time = this.setTime;
      this.startTime();
    } else {
      this.time = 0;
      clearInterval(this.timer);
    }
  }
  @Watch("active")
  setTest() {
    this.flag = this.active;
  }

  //computed
  get getTime() {
    return Math.floor(this.time / 60) + "분" + (this.time % 60) + "초";
  }

  async reSendCode() {
    const randomNumber: string = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, "0");
    const message = "본인확인 인증번호(" + randomNumber + ")를 입력해 주세요.";
    try {
      const res = await this.$store.dispatch("sendMessage", {
        messageContent: message,
        randomNumber: randomNumber,
        receiver: [
          {
            mobile: this.btnContent,
          },
        ],
        isAuth: true,
      });
      if (!res.result) {
        this.showDialog("발송 실패 ERROR CODE : " + res.status, "X");
        return false;
      }
      this.showDialog("재전송 되었습니다.", "ExclamationMark");
      this.time = this.setTime;
    } catch (e) {
      this.showDialog("오류 발생", "X");
      return false;
    }
  }
  showDialog(msg: string, logo: string) {
    this.dialMsg = msg;
    this.dialogLogo = logo;
    this.alertDialog = true;
  }
  hideDialog() {
    this.alertDialog = false;
  }
  async codeValidation() {
    try {
      const result = await this.$store.dispatch("codeValidation", {
        phoneNumber: this.btnContent,
        code: this.code,
        type: "phone",
      });
      if (result) {
        this.showDialog("인증 되었습니다.", "Check");
        this.code = "";
        this.$emit("modalclose", "phone");
      } else {
        this.showDialog("인증코드를 다시 확인해 주세요.", "X");
      }
    } catch (e) {
      this.showDialog("오류 발생", "X");
      return false;
    }
  }
  closeSelf() {
    this.code = "";
    this.$emit("hide");
  }
  countDown() {
    if (this.time > 0) {
      this.time--;
    } else if (this.time === 0) {
      this.showDialog("인증 제한시간이 초과되었습니다.", "X");
      this.closeSelf();
    }
  }
  //타이머
  startTime() {
    this.timer = setInterval(() => this.countDown(), 1000);
  }
}
</script>

<style scoped></style>
