<template>
  <div>
    <v-toolbar dark color="info" style="height: 6vh">
      <v-toolbar-title class="font-weight-bold">거주지 검색</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closeSelf">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div style="background-color: white; height: 94vh" id="layer"></div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

declare global {
  interface Window {
    daum: any;
  }
}

@Component({})
export default class MapDialog extends Vue {
  private selected = false;
  private address = "";

  mounted() {
    this.kakaoPostCode();
    this.selected = false;
  }

  @Watch("selected")
  watchClose() {
    this.closeSelf();
  }

  kakaoPostCode() {
    const element_layer = document.getElementById("layer");
    new window.daum.Postcode({
      oncomplete: (data: {
        userSelectedType: string;
        roadAddress: string;
        jibunAddress: string;
      }) => {
        if (data.userSelectedType === "R") {
          // 사용자가 도로명 주소를 선택했을 경우
          this.address = data.roadAddress;
          this.selected = true;
        } else {
          // 사용자가 지번 주소를 선택했을 경우(J)
          this.address = data.jibunAddress;
          this.selected = true;
        }
      },
      width: "100%",
    }).embed(element_layer);
  }

  closeSelf() {
    this.selected = false;
    this.kakaoPostCode();
    this.$emit("hide");
  }
}
</script>

<style scoped></style>
