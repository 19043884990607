import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from "@/router";
import axios from "@/plugins/axios";
import { AxiosError } from "axios";
import {
  ILoginData,
  IRegisterData1,
  IRegisterData2,
  IUserInfo,
  IValidationCodeData,
} from "@/interfaces/interface";
import dashboard from "@/store/module/dashboard.vuex";
import VueCookies from "vue-cookies";

Vue.use(Vuex);
Vue.use(VueCookies);

export interface State {
  token: string;
  isLogin: boolean;
  isLoginError: boolean;
  loginIdSave: boolean;
  loginPasswordSave: boolean;
  loginId: string;
  loginPassword: string;
  userInfo: IUserInfo;
  isAdmin: boolean;
}

export default new Vuex.Store({
  state: {
    token: "",
    isAdmin: false,
    isLogin: false,
    isLoginError: false,
    loginIdSave: false,
    loginPasswordSave: false,
    loginId: "",
    loginPassword: "",
    userInfo: {
      userId: "",
      userName: "",
      userPassword: "",
      userPhoneNumber: "",
      userGender: 0,
      userBirthDate: "",
      userAddress: "",
      userMarried: 0,
      userInterest: [],
      userFamily: 0,
      userLifeStage: 0,
      used: false,
    },
  },
  getters: {
    token: (state) => state.token,
    userInfo: (state) => state.userInfo,
  },
  mutations: {
    //state 값 변화
    insertUserInfo(state: State, payload: IRegisterData1) {
      state.userInfo.userId = payload.userId;
      state.userInfo.userName = payload.userName;
      state.userInfo.userPassword = payload.userPassword;
      state.userInfo.userPhoneNumber = payload.userPhoneNumber.toString();
    },
    insertUserInfoContinue(state: State, payload: IRegisterData2) {
      state.userInfo.userGender = Number(payload.userGender);
      state.userInfo.userBirthDate = payload.userBirthDate;
      state.userInfo.userAddress = payload.userAddress;
      state.userInfo.userMarried = Number(payload.userMarried);
      state.userInfo.userInterest = payload.userInterest;
      state.userInfo.userFamily = Number(payload.userFamily);
      state.userInfo.userLifeStage = Number(payload.userLifeStage);
      state.userInfo.used = payload.used;
    },
    clearUserInfo(state: State) {
      state.userInfo.userId = "";
      state.userInfo.userName = "";
      state.userInfo.userPassword = "";
      state.userInfo.userPhoneNumber = "";
      state.userInfo.userGender = 0;
      state.userInfo.userBirthDate = "";
      state.userInfo.userAddress = "";
      state.userInfo.userMarried = 0;
      state.userInfo.userInterest = [];
      state.userInfo.userFamily = 0;
      state.userInfo.userLifeStage = 0;
    },
    //로그인 성공
    loginSuccess(
      state: State,
      { token, isAdmin }: { token: string; isAdmin: boolean }
    ) {
      state.token = token;
      // axios.defaults.headers.common['Authorization']=`Bearer ${state.token}`
      state.isLogin = true;
      state.isAdmin = isAdmin;
      state.isLoginError = false;
    },
    //로그인 실패
    loginError(state: State) {
      state.isLoginError = true;
      state.isLogin = false;
      state.isAdmin = false;
    },
    logout(state: State) {
      state.token = "";
      state.isLoginError = false;
      state.isLogin = false;
      state.isAdmin = false;
      state.userInfo.userId = "";
      state.userInfo.userName = "";
      state.userInfo.userPassword = "";
      state.userInfo.userPhoneNumber = "";
      state.userInfo.userGender = null;
      state.userInfo.userBirthDate = "";
      state.userInfo.userAddress = "";
      state.userInfo.userMarried = null;
      state.userInfo.userInterest = [];
      state.userInfo.userFamily = null;
      state.userInfo.userLifeStage = null;
    },
    //회원탈퇴
    deleteUser(state: State) {
      state.token = "";
      state.loginId = "";
      state.loginPassword = "";
      state.loginPasswordSave = false;
      state.isLoginError = false;
      state.isLogin = false;
      state.userInfo.userId = "";
      state.userInfo.userName = "";
      state.userInfo.userPassword = "";
      state.userInfo.userPhoneNumber = "";
      state.userInfo.userGender = null;
      state.userInfo.userBirthDate = "";
      state.userInfo.userAddress = "";
      state.userInfo.userMarried = null;
      state.userInfo.userInterest = [];
      state.userInfo.userFamily = null;
      state.userInfo.userLifeStage = null;
    },
  },
  actions: {
    //회원가입 입력 데이터 저장
    async userInputData(
      { commit },
      payLoad: IRegisterData1
    ): Promise<{ result: boolean; msg?: string }> {
      try {
        const res = await axios.post("users/signup/check", payLoad);
        const result = {
          result: res.data.result,
          msg: res.data.msg,
        };
        commit("insertUserInfo", payLoad);
        return result;
      } catch (e) {
        if (e instanceof AxiosError) {
          const { response } = e as AxiosError<{ message: string[] }>;
          const data = response?.data;
          const msg = data?.message[0];
          return {
            result: false,
            msg,
          };
        }
        return {
          result: false,
          msg: "unknown error",
        };
      }
    },
    async userInputDataContinue({ commit }, payLoad) {
      try {
        const res = await axios.post("users/signup/checkcontinue", {
          ...payLoad,
        });
        const result = {
          result: res.data.result,
          msg: res.data.msg,
        };
        commit("insertUserInfoContinue", payLoad);
        return result;
      } catch (e) {
        if (e instanceof AxiosError) {
          const { response } = e as AxiosError<{ message: string[] }>;
          const data = response?.data;
          const msg = data?.message[0];
          console.log(e);
          return {
            result: false,
            msg,
          };
        }
        return {
          result: false,
          msg: "unknown error",
        };
      }
    },
    //회원가입
    async registerUser({ state, commit }) {
      try {
        await axios.post("/users", {
          ...state.userInfo,
        });
        await router.push("/");
        commit("clearUserInfo");
        localStorage.clear();
      } catch (e) {
        if (e instanceof AxiosError) {
          console.log(e);
          const { response } = e as AxiosError<{ message: string[] }>;
          const data = response?.data;
          const msg = data?.message[0];
          return msg;
        }
      }
    },
    //회원정보 불러오기
    async getUserInfo(context, payLoad) {
      const res = await axios.get("/users/info/" + payLoad.userId);
      return res.data;
    },
    //profile 정보변경
    async modifyProfile(context, payLoad) {
      try {
        await axios.patch("/users", {
          ...payLoad,
        });
      } catch (e) {
        if (e instanceof AxiosError) {
          console.log(e);
          const { response } = e as AxiosError<{ message: string[] }>;
          const data = response?.data;
          const msg = data?.message[0];
          return msg;
        }
      }
    },
    //password변경
    async modifyPassword(context, payLoad) {
      try {
        const res = await axios.patch("/users/password", {
          ...payLoad,
        });
        return res.data;
      } catch (e) {
        if (e instanceof AxiosError) {
          const { response } = e as AxiosError<{
            message: string[];
            result: boolean;
          }>;
          const data = response?.data;
          const msg = data?.message[0];
          const result = data?.result;
          console.log(e);
          return {
            result,
            msg,
          };
        }
      }
    },

    //회원탈퇴
    async deleteUser({ state, commit }) {
      try {
        await axios.delete("/users/" + state.userInfo.userId);
        commit("deleteUser");
        localStorage.clear();
      } catch (e) {
        console.log(e);
      }
    },

    //로그인 시도
    async loginValidate({ commit }, payLoad: ILoginData) {
      try {
        const {
          loginId: userId,
          loginPassword: userPassword,
          role: role,
        } = payLoad;

        const res = await axios.post<{
          result: boolean;
          token: string;
          msg?: string;
        }>("/users/login", {
          userId,
          userPassword,
          role,
        });

        const {
          data: { result, token, msg },
        } = res;

        if (result) {
          /*
           토큰 저장 요청
          */
          if (role === "admin") {
            commit("loginSuccess", { token, isAdmin: true });
          } else {
            commit("loginSuccess", { token, isAdmin: false });
          }

          return {
            result,
            msg,
          };
        } else {
          commit("loginError");
          return {
            result: res.data.result,
            msg: res.data.msg,
          };
        }
      } catch (e) {
        if (e instanceof AxiosError) {
          const { response } = e as AxiosError<{ message: string[] }>;
          const data = response?.data;
          const msg = data?.message[0];
          return {
            result: false,
            msg,
          };
        }
        return {
          result: false,
          msg: "unknown error",
        };
      }
    },

    //아이디 체크
    async checkUserId(
      context,
      payLoad: { userId: string }
    ): Promise<{ result: boolean; msg?: string }> {
      try {
        const res = await axios.get("/users/id/" + payLoad.userId);
        return {
          result: res.data.result,
          msg: res.data.msg,
        };
      } catch (e) {
        if (e instanceof AxiosError) {
          const { response } = e as AxiosError<{ message: string[] }>;
          const data = response?.data;
          const msg = data?.message[0];
          return {
            result: false,
            msg,
          };
        }
        return {
          result: false,
          msg: "unknown error",
        };
      }
    },

    //핸드폰번호 체크
    async checkPhoneNumber(
      context,
      payLoad: { userPhoneNumber: string }
    ): Promise<{ result: boolean; msg?: string }> {
      try {
        const res = await axios.get("/users/phone/" + payLoad.userPhoneNumber);
        return {
          result: res.data.result,
          msg: res.data.msg,
        };
      } catch (e) {
        if (e instanceof AxiosError) {
          const { response } = e as AxiosError<{ message: string[] }>;
          const data = response?.data;
          const msg = data?.message[0];
          return {
            result: false,
            msg,
          };
        }
        return {
          result: false,
          msg: "unknown error",
        };
      }
    },

    //이메일 인증코드
    async sendEmail(context, payLoad: { email: string }) {
      try {
        const res = await axios.post("/sendmail", {
          email: payLoad.email,
        });
        return {
          status: res.data.status,
          result: res.data.result,
        };
      } catch (e) {
        console.log(e);
      }
    },
    //문자발송
    async sendMessage(
      context,
      payLoad: {
        messageContent: string;
        receiver: [];
        isAuth: boolean;
        randomNumber?: string;
      }
    ) {
      try {
        const res = await axios.post("/sms", {
          title: "발송 제목",
          message: payLoad.messageContent,
          receiver: payLoad.receiver,
          randomNumber: payLoad.randomNumber,
          isAuth: payLoad.isAuth,
        });
        return {
          status: res.data.status,
          result: res.data.result,
        };
      } catch (e) {
        console.log(e);
      }
    },

    //인증코드 검증
    async codeValidation(context, payLoad: IValidationCodeData) {
      if (payLoad.type === "email") {
        const { data } = await axios.get(
          "/sendmail?email=" + payLoad.email + "&code=" + payLoad.code
        );
        return data;
      } else if (payLoad.type === "phone") {
        const { data } = await axios.get(
          "/sms?userphonenumber=" +
            payLoad.phoneNumber +
            "&code=" +
            payLoad.code
        );
        return data;
      }
    },
    async getList(context, payLoad) {
      try {
        const res = await axios.get("admin/monitoring/list", {
          params: {
            userStatus: payLoad.userStatus,
            dataStatus: payLoad.dataStatus,
          },
        });

        return res.data;
      } catch (e) {
        console.log("e:", e);
        return false;
      }
    },
    async getProfileList(context, payLoad) {
      try {
        const res = await axios.get("admin/monitoring/list/profile", {
          params: {
            users: payLoad.users,
          },
        });
        return res.data;
      } catch (e) {
        console.log("e:", e);
      }
    },
    async getAppList(context, payLoad) {
      try {
        const res = await axios.get("admin/monitoring/list/app", {
          params: {
            users: payLoad.users,
            dates: payLoad.dates,
          },
        });
        return res.data;
      } catch (e) {
        return false;
      }
    },
    async getNotCrawledPackageList() {
      try {
        const res = await axios.get("admin/monitoring/list/crawling");
        if (res.data) {
          return res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getUserId() {
      try {
        const res = await axios.get("/users/user");
        if (res.data) {
          this.state.loginId = res.data;
          await router.push("/mypage/main");
        }
      } catch (e) {
        return false;
      }
    },
    //토큰 검증
    async verify() {
      try {
        const res = await axios.get("/users");
        if (res) return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async dashboardVerify() {
      try {
        const cookies = Vue.$cookies.get("vuex");
        const { token } = cookies.token;
        this.state.token = token;
        const res = await axios.get(
          "https://pmidata.co.kr/api/user/auth-verify"
        );
        if (res) return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async dashboardLogout() {
      Vue.$cookies.remove("vuex");
      //console.log(Vue.$cookies.get("vuex"));
    },
  },
  modules: { dashboard },
  plugins: [createPersistedState()],
});
