<template>
  <v-app>
    <v-container class="pa-0 col-md-4 col-lg-3 col-xl-3" fill-height>
      <div class="info divToolbar" style="position: relative">
        <v-img
          src="../../assets/arrow.png"
          style="position: absolute; cursor: pointer"
          width="25px"
          class="mt-5 ml-2"
          @click="goBack"
        ></v-img>
        <b>MyPage</b>
      </div>
      <v-container>
        <div style="height: 5vh"></div>
        <div style="height: 65vh; line-height: 70vh">
          <v-btn
            class="mt-5"
            rounded
            outlined
            block
            color="cyan"
            style="color: black"
          >
            앱 수집 설정 방법
          </v-btn>
          <v-btn
            class="mt-5"
            rounded
            block
            dark
            color="indigo"
            @click="profile"
          >
            Profile 변경
          </v-btn>
          <v-btn
            class="mt-5"
            rounded
            block
            dark
            color="indigo"
            @click="modifyPassword"
          >
            비밀번호 변경
          </v-btn>
          <v-btn
            class="mt-5"
            rounded
            dark
            block
            color="grey lighten-1"
            @click="showSecessionDialog"
          >
            회원 탈퇴
          </v-btn>
        </div>
      </v-container>
      <v-dialog
        v-model="passwordDialog"
        persistent
        width="600px"
        class="pa-0 col-md-4 col-lg-3 col-xl-3"
      >
        <password-modify-dialog @hide="hidePasswordDialog">
          <template #btnname>변경</template>
          <template #closebtnname>취소</template>
        </password-modify-dialog>
      </v-dialog>
      <v-dialog v-model="secessionDialog" persistent>
        <MyDialog :is-confirm="true" @hide="hideDialog" @confirm="deleteUser">
          <template #body>
            <h3>회원을 탈퇴하시겠습니까?</h3>
          </template>
          <template #confirmName>탈퇴</template>
          <template #btnname>취소</template>
        </MyDialog>
      </v-dialog>
      <v-dialog v-model="dialog" persistent>
        <MyDialog @hide="hideDialog" :mode="alertMode">
          <template #body>
            <h3>{{ dialMsg }}</h3>
          </template>
          <template #btnname>변경</template>
        </MyDialog>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import PasswordModifyDialog from "@/components/PasswordModifyDialog.vue";
import MyDialog from "@/components/MyDialog.vue";

declare global {
  interface Window {
    MyPageView: {
      components: MyPageView;
    };
    JSBridge: any;
  }
}

@Component({ components: { MyDialog, PasswordModifyDialog } })
export default class MyPageView extends Vue {
  private passwordDialog = false;
  private secessionDialog = false;
  private userId = this.$store.state.loginId;
  private dialMsg = "";
  private dialog = false;
  private alertMode = true;

  beforeMount() {
    window["MyPageView"] = {
      components: this,
    };
  }
  profile() {
    this.$router.push("/mypage/modify");
  }
  modifyPassword() {
    this.passwordDialog = true;
  }
  async deleteUser() {
    await this.$store.dispatch("deleteUser");
    this.dialMsg = "탈퇴가 완료되었습니다.";
    this.showDialog();

    const isMobile = /Mobi/i.test(window.navigator.userAgent);
    if (isMobile) {
      window.JSBridge.deleteUser();
    }
  }
  showSecessionDialog() {
    this.secessionDialog = true;
  }
  hidePasswordDialog() {
    this.passwordDialog = false;
  }
  showDialog() {
    this.dialog = true;
  }
  hideDialog(mode?: boolean) {
    this.secessionDialog = false;
    this.dialog = false;
    if (mode === true) this.$router.push("/");
  }
  goBack() {
    // this.$router.push("/home");
    const isMobile = /Mobi/i.test(window.navigator.userAgent);
    if (isMobile) {
      window.JSBridge.goHome();
    }
  }
}
</script>

<style scoped>
div .divToolbar {
  height: 20vh;
  width: 100%;
  text-align: center;
  color: white;
  line-height: 20vh;
  border-radius: 0% 0% 10% 10%;
}
</style>
