<template>
  <v-app-bar app color="indigo" dark>
    <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn small color="error" @click="logout">로그아웃</v-btn>
  </v-app-bar>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import router from "@/router";

@Component({})
export default class CustomToolbar extends Vue {
  @Prop() toolbarTitle?: string;

  async logout() {
    localStorage.clear();
    this.$store.commit("logout");
    await router.push("/admin/login");
  }
}
</script>

<style scoped></style>
