import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import VueAxios from "vue-axios";
import axios from "@/plugins/axios";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import "@/assets/css/styleguide.css";
import "@/assets/css/globals.css";

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.$cookies.config("60*30");

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});

Vue.use(VueAxios, axios);
// vue-axios
new Vue({
  router,
  store,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
