<template>
  <v-app>
    <v-main class="background">
      <v-container style="max-width: 350px" fill-height>
        <v-layout align-center row wrap>
          <v-flex xs12>
            <h1 style="text-align: center; color: white" class="mb-5">
              In App Admin
            </h1>
            <div style="height: 5vh"></div>
            <div style="width: 100%; text-align: center">
              <v-img
                src="../../assets/login-icon.png"
                style="width: 106px; display: block; margin: 0px auto"
              ></v-img>
            </div>
            <v-text-field
              label="ID"
              v-model="loginId"
              prepend-inner-icon="mdi-account"
              outlined
              color="black"
              background-color="white"
              class="mt-5"
            ></v-text-field>
            <v-text-field
              label="Password"
              type="password"
              v-model="loginPassword"
              prepend-inner-icon="mdi-lock"
              outlined
              color="black"
              background-color="white"
            ></v-text-field>
            <v-btn
              color="yellow lighten-3 text-capitalize"
              depressed
              large
              block
              class="mb-3"
              @click="loginValidate"
            >
              Login
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-dialog v-model="myDialog" width="400">
      <MyDialog @hide="hideDialog" :logo="dialogLogo">
        <template #body>
          <h3>{{ dialMsg }}</h3>
        </template>
        <template #btnname> 닫기 </template>
      </MyDialog>
    </v-dialog>
  </v-app>
</template>
<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import MyDialog from "@/components/MyDialog.vue";
import router from "@/router";

@Component({ components: { MyDialog } })
export default class AdminLoginView extends Vue {
  private loginId = "";
  private loginPassword = "";
  private myDialog = false;
  private dialMsg = "";
  private btnContent = "";
  private dialogLogo = "";

  async loginValidate() {
    this.loginId = this.loginId.replace(/ /g, "");
    try {
      const res = await this.$store.dispatch("loginValidate", {
        loginId: this.loginId,
        loginPassword: this.loginPassword,
        role: "admin",
      });
      if (!res.result) {
        this.showDialog(res.msg);
        this.loginPassword = "";
        return false;
      }
      await router.push("/admin/monitoring");
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  hideDialog() {
    this.myDialog = false;
  }

  showDialog(msg: string) {
    this.dialogLogo = "Reload";
    this.myDialog = true;
    this.btnContent = "닫기";
    this.dialMsg = msg;
  }
}
</script>

<style scoped>
.background {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  background-image: url("../../assets/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>

<style scoped></style>
