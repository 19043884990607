<template>
  <div class="pb-5">
    <v-form>
      <v-card class="mx-4">
        <v-list style="height: 80vh" class="overflow-auto">
          <v-list-item-group color="primary" multiple v-model="selectCategory">
            <v-list-item v-for="category in categories" :key="category.no">
              <v-list-item-content>
                <v-list-item-title class="text-center" v-text="category.text">
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
      <v-flex class="text-center ma-5">
        <v-btn
          class="mr-3"
          color="primary"
          outlined
          tile
          @click="resetCategory()"
          >초기화</v-btn
        >

        <v-btn tile color="primary">저장</v-btn>
      </v-flex>
    </v-form>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class CategoryFilter extends Vue {
  private selectCategory = [];
  private categories: Array<{ no: number; text: string }> = [];

  resetCategory() {
    this.selectCategory = [];
  }
}
</script>

<style scoped></style>
