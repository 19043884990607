import { render, staticRenderFns } from "./JoinContinueView.vue?vue&type=template&id=d26bc086&scoped=true"
import script from "./JoinContinueView.vue?vue&type=script&lang=ts"
export * from "./JoinContinueView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d26bc086",
  null
  
)

export default component.exports