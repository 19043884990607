<template>
  <v-app>
    <v-container class="pa-0 col-md-4 col-lg-3 col-xl-3" fill-height>
      <v-card
        class="mx-auto"
        align-center
        min-width="100%"
        min-height="100vh"
        tile
      >
        <v-toolbar flat color="info" dark>
          <v-toolbar-title class="font-weight-bold">회원가입</v-toolbar-title>
        </v-toolbar>
        <v-main align-center>
          <v-form ref="form" lazy-validation class="pa-5">
            <div class="d-flex">
              <h4>아이디(이메일)</h4>
              &nbsp;&nbsp;
              <span style="color: red" v-show="!emailValid"
                ><h5>이메일 인증이 필요합니다.</h5></span
              >
              <span style="color: green" v-show="emailValid"
                ><h5>인증되었습니다.</h5></span
              >
            </div>
            <div class="d-flex justify-md-space-between">
              <v-text-field
                v-model="userInfo.userId"
                :readonly="emailValid"
                color="info"
                ref="email"
                label="아이디(이메일)"
              >
              </v-text-field>
              <v-btn
                depressed
                color="grey darken-2"
                :disabled="emailValid"
                class="ma-0"
                small
                style="color: white"
                @click="emailAuth"
                >인증하기</v-btn
              >
            </div>
            <h4>비밀번호</h4>
            <v-text-field
              v-model="userInfo.userPassword"
              ref="password"
              type="password"
              color="info"
              label="비밀번호"
            ></v-text-field>
            <h4>비밀번호 확인</h4>
            <v-text-field
              v-model="userInfo.userPasswordCheck"
              ref="passwordCheck"
              type="password"
              color="info"
              label="비밀번호 확인"
            ></v-text-field>
            <h4>이름</h4>
            <v-text-field
              v-model="userInfo.userName"
              ref="name"
              color="info"
              label="이름"
            ></v-text-field>
            <div class="d-flex">
              <h4>휴대폰 번호</h4>
              &nbsp;&nbsp;
              <span style="color: red" v-show="!numberValid">
                <h5>휴대폰 인증이 필요합니다.</h5>
              </span>
              <span style="color: green" v-show="numberValid">
                <h5>인증되었습니다.</h5>
              </span>
            </div>
            <div class="d-flex justify-md-space-between">
              <v-text-field
                v-model="userInfo.userPhoneNumber"
                :readonly="numberValid"
                type="number"
                ref="number"
                maxlength="11"
                oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                label="휴대폰 번호"
                color="info"
                hint="( - 없이 입력)"
                persistent-hint
              >
              </v-text-field>
              <v-btn
                depressed
                color="grey darken-2"
                :disabled="numberValid"
                class="ma-0"
                small
                style="color: white"
                @click="PhoneNumberAuth"
              >
                인증하기
              </v-btn>
            </div>
            <v-checkbox
              v-model="userInfo.checkAll"
              label="모두 동의합니다."
              color="info"
              required
              blok
              @change="checkAllReturn"
            ></v-checkbox>
            <div class="d-flex justify-md-space-between">
              <v-checkbox
                v-model="check1"
                class="pa-0 ma-0"
                label="이용 약관 동의"
                color="info"
                required
                @change="checkReturn"
              ></v-checkbox>
              <v-btn
                depressed
                color="grey darken-2"
                class="ma-0"
                dark
                small
                @click="showDialog('이용 약관 동의', '')"
                >보기</v-btn
              >
            </div>
            <div class="d-flex justify-md-space-between">
              <v-checkbox
                v-model="check2"
                class="pa-0 ma-0"
                label="개인정보 취급 방침 동의"
                color="info"
                @change="checkReturn"
              ></v-checkbox>
              <v-btn
                depressed
                color="grey darken-2"
                class="ma-0"
                dark
                small
                @click="showDialog('개인정보 취급 방침 동의', '')"
                >보기</v-btn
              >
            </div>
            <v-btn
              class="mt-2"
              outlined
              block
              color="indigo"
              @click="formValidator"
            >
              회원 Profile 입력하고 회원가입 완료하기
            </v-btn>
            <v-btn
              class="mt-2"
              block
              color="grey darken-1"
              dark
              depressed
              to="/"
            >
              취소
            </v-btn>
          </v-form>
        </v-main>
      </v-card>
      <v-dialog v-model="alertDialog" width="400">
        <MyDialog @hide="hideDialog" :logo="dialogLogo">
          <template #body>
            <h3>{{ dialMsg }}</h3>
          </template>
          <template #btnname> 닫기 </template>
        </MyDialog>
      </v-dialog>
      <v-dialog v-model="emailDialog" width="400" persistent>
        <EmailAuthDialog
          @hide="hideEmailDialog"
          @modalclose="authModalClose"
          :btn-content="this.userInfo.userId"
          :active="emailDialog"
        >
          <template #body>
            <h3>{{ dialMsg }}</h3>
          </template>
          <template #btnname> 인증하기 </template>
          <template #closebtnname> 취소 </template>
        </EmailAuthDialog>
      </v-dialog>
      <v-dialog v-model="phoneDialog" width="400" persistent>
        <PhoneAuthDialog
          @hide="hidePhoneDialog"
          @modalclose="authModalClose"
          :btn-content="this.userInfo.userPhoneNumber"
          :active="phoneDialog"
        >
          <template #body>
            <h3>{{ dialMsg }}</h3>
          </template>
          <template #btnname> 인증하기 </template>
          <template #closebtnname> 취소 </template>
        </PhoneAuthDialog>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import MyDialog from "../../components/MyDialog.vue";
import EmailAuthDialog from "../../components/EmailAuthDialog.vue";
import PhoneAuthDialog from "@/components/PhoneAuthDialog.vue";
import { Component, Vue } from "vue-property-decorator";
@Component({ components: { MyDialog, EmailAuthDialog, PhoneAuthDialog } })
export default class JoinView extends Vue {
  $refs!: {
    email: HTMLFormElement;
    number: HTMLFormElement;
    password: HTMLFormElement;
    passwordCheck: HTMLFormElement;
    name: HTMLFormElement;
  };

  private userInfo = {
    userId: "",
    userName: "",
    userPassword: "",
    userPasswordCheck: "",
    userPhoneNumber: "",
    checkAll: false,
  };
  private alertDialog = false;
  private dialogLogo = "";
  private emailDialog = false;
  private phoneDialog = false;
  private dialMsg = "";
  private check1 = false;
  private check2 = false;
  private emailValid = false;
  private numberValid = false;

  checkAllReturn() {
    if (this.userInfo.checkAll) {
      this.check1 = true;
      this.check2 = true;
    } else {
      this.check1 = false;
      this.check2 = false;
    }
  }
  checkReturn() {
    this.userInfo.checkAll = this.check1 && this.check2;
  }
  async emailAuth() {
    const validateEmail =
      /^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/;

    if (!validateEmail.test(this.userInfo.userId)) {
      this.showDialog("아이디가 이메일 형식이 아닙니다.", "X");
      // this.$nextTick(() => this.$refs.email.focus());
      this.$refs.email.focus();
      return false;
    }
    try {
      let res = await this.$store.dispatch("checkUserId", {
        userId: this.userInfo.userId,
      });

      if (!res.result) {
        this.showDialog(res.msg, "X");
        this.emailValid = false;
        return false;
      }
      this.emailDialog = true;
      this.dialMsg = this.userInfo.userId;

      res = await this.$store.dispatch("sendEmail", {
        email: this.userInfo.userId,
        type: "email",
      });
      if (!res.result) {
        this.hideEmailDialog();
        this.showDialog("발송 실패 ERROR CODE : " + res.status, "X");
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  async PhoneNumberAuth() {
    if (this.userInfo.userPhoneNumber.length !== 11) {
      this.showDialog("유효하지 않는 핸드폰 번호 입니다.", "X");
      this.$refs.number.focus();
      return false;
    }
    try {
      let res = await this.$store.dispatch("checkPhoneNumber", {
        userPhoneNumber: this.userInfo.userPhoneNumber,
      });
      if (!res.result) {
        this.showDialog(res.msg, "X");
        this.numberValid = false;
        return false;
      }
      this.phoneDialog = true;
      this.dialMsg = this.userInfo.userPhoneNumber;

      //인증 문자 발송
      const randomNumber: string = Math.floor(Math.random() * 1000000)
        .toString()
        .padStart(6, "0");

      res = await this.$store.dispatch("sendMessage", {
        messageContent:
          "본인확인 인증번호(" + randomNumber + ")를 입력해 주세요.",
        randomNumber: randomNumber,
        receiver: [
          {
            mobile: this.userInfo.userPhoneNumber,
          },
        ],
        isAuth: true,
      });
      if (!res.result) {
        this.hidePhoneDialog();
        this.showDialog("발송 실패 ERROR CODE : " + res.status, "X");
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  async formValidator() {
    //인증 하기 필수
    if (!this.emailValid) {
      this.showDialog("이메일 인증이 필요합니다.", "");
      this.$refs.email.focus();
      return false;
    }
    if (!this.numberValid) {
      this.showDialog("휴대폰 인증이 필요합니다.", "");
      this.$refs.number.focus();
      return false;
    }

    try {
      const res = await this.$store.dispatch("userInputData", this.userInfo);
      if (!res.result) {
        this.showDialog(res.msg, "X");
        return false;
      } else {
        await this.$router.push("/joincontinue");
      }
    } catch (e) {
      console.log(e);
    }
  }
  authModalClose(type: string) {
    if (type === "email") {
      this.emailDialog = false;
      this.emailValid = true;
    }
    if (type === "phone") {
      this.phoneDialog = false;
      this.numberValid = true;
    }
  }
  showDialog(msg: string, logo: string) {
    this.alertDialog = true;
    this.dialogLogo = logo;
    this.dialMsg = msg;
  }
  hideDialog() {
    this.alertDialog = false;
  }
  hideEmailDialog() {
    this.emailDialog = false;
  }
  hidePhoneDialog() {
    this.phoneDialog = false;
  }
}
</script>
