import axios from "axios";
import store from "@/store/index";

const instance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 20000,
});

// instance

instance.interceptors.request.use((config) => {
  const {
    getters: { token },
  } = store;
  if (token) {
    if (!config.headers) config.headers = {};
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

/*instance.interceptors.response.use(
  () => {
    console.log("a");
  },
  (error) => {
    console.log(error);
    return error;
  }
);*/
export default instance;
