<template>
  <v-flex>
    <v-card class="mt-10" outlined tile>
      <div class="d-flex">
        <div class="col-xs-12 pb-5" style="width: 100%">
          <div class="filter-title ml-5 mt-3 mb-1">상세 분류</div>
          <v-flex class="row ml-5">
            <v-card
              class="col-11 col-md-5 col-xl-2 pa-0 mt-5"
              :elevation="0"
              tile
            >
              <div class="mb-1 filer-detail-label">성별</div>
              <v-card tile :elevation="0">
                <v-select
                  :elevation="0"
                  v-model="filterValue.selectedGender"
                  :items="gender"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  outlined
                  flat
                  hide-details="false"
                  label=""
                  multiple
                  return-object
                  @change="AllSelectedCheck('selectedGender')"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="black--text body-2">
                      {{ getSelectLabel("selectedGender") }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-group>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                            class="subheading"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item-group>
                    </template>
                  </template>
                </v-select>
              </v-card>
            </v-card>
            <div class="col-1 pa-0"></div>
            <v-card
              class="col-11 col-md-5 col-xl-2 pa-0 mt-5"
              :elevation="0"
              tile
            >
              <div class="mb-1 filer-detail-label">거주 지역</div>
              <v-card tile :elevation="0">
                <v-select
                  v-model="filterValue.selectedAddress"
                  :items="address"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  flat
                  outlined
                  label=""
                  hide-details="false"
                  multiple
                  return-object
                  @change="AllSelectedCheck('selectedAddress')"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="black--text body-2">
                      {{ getSelectLabel("selectedAddress") }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                          class="subheading"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </v-card>
            </v-card>
            <div class="col-1 pa-0"></div>
            <v-card
              class="col-11 col-md-5 col-xl-2 pa-0 mt-5"
              :elevation="0"
              tile
            >
              <div class="mb-1 filer-detail-label">관심사</div>
              <v-card tile :elevation="0">
                <v-select
                  v-model="filterValue.selectedInterest"
                  :items="interest"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  outlined
                  flat
                  hide-details="false"
                  label=""
                  multiple
                  return-object
                  @change="AllSelectedCheck('selectedInterest')"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="black--text body-2">
                      {{ getSelectLabel("selectedInterest") }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                          class="subheading"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </v-card>
            </v-card>
            <div class="col-1 pa-0"></div>
            <v-card
              class="col-11 col-md-5 col-xl-2 pa-0 mt-5"
              :elevation="0"
              tile
            >
              <div class="mb-1 filer-detail-label">연령</div>
              <v-card tile :elevation="0">
                <v-select
                  v-model="filterValue.selectedAge"
                  :items="age"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  outlined
                  flat
                  hide-details="false"
                  label=""
                  multiple
                  return-object
                  @change="AllSelectedCheck('selectedAge')"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="black--text body-2">
                      {{ getSelectLabel("selectedAge") }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                          class="subheading"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </v-card>
            </v-card>
            <div class="col-1 pa-0"></div>
          </v-flex>
          <v-flex class="row ml-5">
            <v-card
              class="col-11 col-md-5 col-xl-2 pa-0 mt-5"
              :elevation="0"
              tile
            >
              <div class="mb-1 filer-detail-label">가구 구성</div>
              <v-card tile :elevation="0">
                <v-select
                  v-model="filterValue.selectedFamily"
                  :items="family"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  outlined
                  flat
                  hide-details="false"
                  label=""
                  multiple
                  return-object
                  @change="AllSelectedCheck('selectedFamily')"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="black--text body-2">
                      {{ getSelectLabel("selectedFamily") }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                          class="subheading"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </v-card>
            </v-card>
            <div class="col-1 pa-0"></div>
            <v-card
              class="col-11 col-md-5 col-xl-2 pa-0 mt-5"
              :elevation="0"
              tile
            >
              <div class="mb-1 filer-detail-label">결혼 여부</div>
              <v-card tile :elevation="0">
                <v-select
                  v-model="filterValue.selectedMarried"
                  :items="married"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  outlined
                  flat
                  hide-details="false"
                  label=""
                  multiple
                  return-object
                  @change="AllSelectedCheck('selectedMarried')"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="black--text body-2">
                      {{ getSelectLabel("selectedMarried") }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                          class="subheading"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </v-card>
            </v-card>
            <div class="col-1 pa-0"></div>
            <v-card
              class="col-11 col-md-5 col-xl-2 pa-0 mt-5"
              :elevation="0"
              tile
            >
              <div class="mb-1 filer-detail-label">Life Stage</div>
              <v-card tile :elevation="0">
                <v-select
                  v-model="filterValue.selectedLifeStage"
                  :items="lifeStage"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  outlined
                  flat
                  hide-details="false"
                  label=""
                  multiple
                  return-object
                  @change="AllSelectedCheck('selectedLifeStage')"
                >
                  <template v-slot:selection="data">
                    <span v-if="data.index === 0" class="black--text body-2">
                      {{ getSelectLabel("selectedLifeStage") }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                          class="subheading"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </v-card>
            </v-card>
          </v-flex>
          <div class="ml-5 mt-5">
            <v-tooltip
              v-for="(item, idx) of getFilterValueExceptAll('selectedGender')"
              :key="`gender-${idx}`"
              bottom
              :disabled="getDisplayTooltip(item.text)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="filer-chips ma-0 caption ml-1 mt-1"
                  label
                  close
                  @click="deleteDetailChip('selectedGender', item)"
                  @click:close="deleteDetailChip('selectedGender', item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ getChipText(item.text) }}
                </v-chip>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
            <v-tooltip
              v-for="(item, idx) of getFilterValueExceptAll('selectedAddress')"
              :key="`address-${idx}`"
              bottom
              :disabled="getDisplayTooltip(item.text)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-0 caption filer-chips ml-1 mt-1"
                  label
                  close
                  @click="deleteDetailChip('selectedAddress', item)"
                  @click:close="deleteDetailChip('selectedAddress', item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ getChipText(item.text) }}
                </v-chip>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
            <v-tooltip
              v-for="(item, idx) of getFilterValueExceptAll('selectedInterest')"
              :key="`interest-${idx}`"
              bottom
              :disabled="getDisplayTooltip(item.text)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-0 caption filer-chips ml-1 mt-1"
                  label
                  close
                  @click="deleteDetailChip('selectedInterest', item)"
                  @click:close="deleteDetailChip('selectedInterest', item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ getChipText(item.text) }}
                </v-chip>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>

            <v-tooltip
              v-for="(item, idx) of getFilterValueExceptAll('selectedAge')"
              :key="`age-${idx}`"
              bottom
              :disabled="getDisplayTooltip(item.text)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-0 caption filer-chips ml-1 mt-1"
                  label
                  close
                  @click="deleteDetailChip('selectedAge', item)"
                  @click:close="deleteDetailChip('selectedAge', item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ getChipText(item.text) }}
                </v-chip>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
            <v-tooltip
              v-for="(item, idx) of getFilterValueExceptAll('selectedFamily')"
              :key="`family-${idx}`"
              bottom
              :disabled="getDisplayTooltip(item.text)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-0 caption filer-chips ml-1 mt-1"
                  label
                  close
                  @click="deleteDetailChip('selectedFamily', item)"
                  @click:close="deleteDetailChip('selectedFamily', item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ getChipText(item.text) }}
                </v-chip>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
            <v-tooltip
              v-for="(item, idx) of getFilterValueExceptAll('selectedMarried')"
              :key="`married-${idx}`"
              bottom
              :disabled="getDisplayTooltip(item.text)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-0 caption filer-chips ml-1 mt-1"
                  label
                  close
                  @click="deleteDetailChip('selectedMarried', item)"
                  @click:close="deleteDetailChip('selectedMarried', item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ getChipText(item.text) }}
                </v-chip>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
            <v-tooltip
              v-for="(item, idx) of getFilterValueExceptAll(
                'selectedLifeStage'
              )"
              :key="`lifeStage-${idx}`"
              bottom
              :disabled="getDisplayTooltip(item.text)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="caption filer-chips ml-1 mt-1"
                  label
                  close
                  @click="deleteDetailChip('selectedLifeStage', item)"
                  @click:close="deleteDetailChip('selectedLifeStage', item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ getChipText(item.text) }}
                </v-chip>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <v-card class="d-flex" :elevation="0" tile>
        <div>
          <div class="filter-title ml-5 mt-3 mb-1">검색 기간</div>
          <div class="row">
            <div class="d-flex col-12 align-center ml-5 mb-5">
              <v-btn-toggle
                v-model="filterValue.searchPeriod"
                color="primary"
                mandatory
              >
                <v-btn small text @click="getDefaultDate" value="1">
                  일주일
                </v-btn>
                <v-btn small text @click="DefaultMonth" value="2"> 한달 </v-btn>
                <v-btn small text @click="DefaultYear" value="3"> 일년 </v-btn>
              </v-btn-toggle>
              <v-menu
                v-if="pickerMode"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div style="width: 300px">
                    <v-text-field
                      class="ml-5"
                      v-model="dateRangeText"
                      label="데이터 기간 설정"
                      single-line
                      hide-details="false"
                      append-icon="mdi-calendar"
                      readonly
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </div>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="filterValue.dates"
                  :type="datePickerType"
                  :key="datePickerType"
                  :max="maxDate"
                  :weekday-format="getDay"
                  :month-format="getMonth"
                  :title-date-format="getHeaderTitleMonth"
                  :header-date-format="getHeaderTitleMonth"
                  :show-current="false"
                  no-title
                  scrollable
                  range
                >
                </v-date-picker>
              </v-menu>
              <v-menu
                v-if="!pickerMode"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div style="width: 300px">
                    <v-text-field
                      class="ml-5"
                      v-model="dateRangeText"
                      label="데이터 기간 설정"
                      single-line
                      hide-details="false"
                      append-icon="mdi-calendar"
                      readonly
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </div>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="filterValue.month"
                  :type="monthPickerType"
                  :key="monthPickerType"
                  :show-current="false"
                  :max="maxDate"
                  locale="ko-KR"
                  no-title
                  scrollable
                >
                </v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
      </v-card>
      <v-divider></v-divider>
      <v-card class="d-flex" :elevation="0" tile>
        <div style="width: 100%">
          <div class="filter-title ml-5 mt-3 mb-1">카테고리</div>
          <div class="row">
            <div class="d-flex col-11 overflow-auto align-center ml-5">
              <v-btn-toggle dense group color="primary" v-model="All">
                <v-btn text value="전체" class="category-btn">전체</v-btn>
              </v-btn-toggle>
              <v-btn-toggle
                v-model="filterValue.category"
                color="primary"
                dense
                group
                multiple
                v-for="category of mainCategoryList"
                :key="category"
              >
                <v-btn :value="category" class="category-btn" text
                  >{{ category }}
                </v-btn>
              </v-btn-toggle>
              <v-btn outlined tile small @click="moreCategory = !moreCategory">
                더보기
                <v-icon v-if="!moreCategory" dense>mdi-plus</v-icon>
                <v-icon v-else dense>mdi-arrow-up</v-icon>
              </v-btn>
            </div>
          </div>
          <v-scroll-y-transition>
            <div class="col-12 mt-5 ml-5" v-show="moreCategory">
              <v-autocomplete
                v-model="filterValue.category"
                :items="categoryList"
                outlined
                dense
                color="#00A880"
                item-color="primary"
                label="어떤 카테고리를 찾으시나요?"
                multiple
                style="width: 300px"
              >
                <template v-slot:selection=""></template>
                <template v-slot:item="{ item }">
                  <v-list-item-group>
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item-group>
                </template>
              </v-autocomplete>

              <v-menu
                v-model="categoryMenu.ganada"
                :close-on-content-click="false"
                bottom
                offset-y
                allow-overflow
                max-height="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="category-btn" v-bind="attrs" v-on="on">
                    가 - 다
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item-group
                    v-model="filterValue.category"
                    color="primary"
                    multiple
                  >
                    <template v-for="item of detailCategories.ganada">
                      <v-list-item :key="item" :value="item">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-menu
                v-model="categoryMenu.ramaba"
                :close-on-content-click="false"
                bottom
                offset-y
                allow-overflow
                max-height="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="category-btn" v-bind="attrs" v-on="on">
                    라 - 바
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item-group
                    v-model="filterValue.category"
                    color="primary"
                    multiple
                  >
                    <template v-for="item of detailCategories.ramaba">
                      <v-list-item :key="item" :value="item">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-menu
                v-model="categoryMenu.saaja"
                :close-on-content-click="false"
                bottom
                offset-y
                allow-overflow
                max-height="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="category-btn" v-bind="attrs" v-on="on">
                    사 - 자
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item-group
                    v-model="filterValue.category"
                    color="primary"
                    multiple
                  >
                    <template v-for="item of detailCategories.saaja">
                      <v-list-item :key="item" :value="item">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-menu
                v-model="categoryMenu.chakatapaha"
                :close-on-content-click="false"
                bottom
                offset-y
                allow-overflow
                max-height="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="category-btn" v-bind="attrs" v-on="on">
                    차 - 하
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item-group
                    v-model="filterValue.category"
                    color="primary"
                    multiple
                  >
                    <template v-for="item of detailCategories.chakatapaha">
                      <v-list-item :key="item" :value="item">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-menu
                v-model="categoryMenu.etc"
                :close-on-content-click="false"
                bottom
                offset-y
                allow-overflow
                max-height="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="category-btn" v-bind="attrs" v-on="on">
                    기타
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item-group
                    v-model="filterValue.category"
                    color="primary"
                    multiple
                  >
                    <template v-for="item of detailCategories.etc">
                      <v-list-item :key="item" :value="item">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </v-scroll-y-transition>
          <div class="ml-5 mb-5 mt-3">
            <v-tooltip
              v-for="(item, idx) of filterValue.category"
              :key="`category-${idx}`"
              bottom
              :disabled="getDisplayTooltip(item)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="caption filer-chips ml-1 mt-1"
                  close
                  label
                  @click="deleteCategoryChip(item)"
                  @click:close="deleteCategoryChip(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ getChipText(item) }}
                </v-chip>
              </template>
              <span>{{ item }}</span>
            </v-tooltip>
          </div>
        </div>
      </v-card>
      <v-divider></v-divider>
      <v-card class="d-flex" :elevation="0" tile>
        <div class="row" style="width: 100%">
          <div class="d-flex col-12 align-center justify-center mt-5 mb-5">
            <v-btn
              color="primary"
              class="mr-3"
              @click="confirm('설정한 필터를 초기화 하시겠습니까?')"
              outlined
              tile
              :disabled="loading"
              >초기화
            </v-btn>
            <v-btn
              color="#001F2B"
              class="mr-3"
              dark
              @click="saveConfirm"
              tile
              :elevation="0"
              :disabled="loading"
              >설정
            </v-btn>
          </div>
        </div>
      </v-card>
      <v-dialog v-model="alertDialog" width="400" persistent>
        <MyDialog
          @hide="hideDialog"
          @confirm="initFilter"
          logo="dialogLogo"
          :is-confirm="dialogConfirm"
        >
          <template #body>
            <h3 v-html="dialMsg">{{ dialMsg }}</h3>
          </template>
          <template #btnname> {{ dialogButtonText }} </template>
          <template #confirmName> 확인 </template>
        </MyDialog>
      </v-dialog>
      <v-dialog v-model="saveDialog" width="400" persistent>
        <MyDialog
          @hide="hideDialog"
          @confirm="setUp(true)"
          logo="dialogLogo"
          :is-confirm="true"
        >
          <template #body>
            <h3 v-html="dialMsg">{{ dialMsg }}</h3>
          </template>
          <template #btnname> 취소 </template>
          <template #confirmName> 확인 </template>
        </MyDialog>
      </v-dialog>
    </v-card>
  </v-flex>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";

import {
  FilterItem,
  IDetailCategories,
  IFilterValue,
} from "@/interfaces/dashboard/interface";
import MyDialog from "@/components/MyDialog.vue";

@Component({ components: { MyDialog } })
export default class DashboardFilter extends Vue {
  @Prop() loading?: boolean;

  private alertDialog = false;
  private saveDialog = false;
  private dialogLogo = "";
  private dialogButtonText = "";
  private dialogConfirm = false;
  private dateMenu = false;
  private dialMsg = "";
  private gender = [
    { no: 0, text: "전체" },
    { no: 1, text: "남자" },
    { no: 2, text: "여자" },
  ];
  private address = [
    { no: 0, text: "전체" },
    { no: 1, text: "서울" },
    { no: 2, text: "부산" },
    { no: 3, text: "대구" },
    { no: 4, text: "인천" },
    { no: 5, text: "광주" },
    { no: 6, text: "대전" },
    { no: 7, text: "울산" },
    { no: 8, text: "경기" },
    { no: 9, text: "강원" },
    { no: 10, text: "충청남도" },
    { no: 11, text: "충청북도" },
    { no: 12, text: "전라남도" },
    { no: 13, text: "전라북도" },
    { no: 14, text: "경상남도" },
    { no: 15, text: "경상북도" },
    { no: 16, text: "제주도" },
  ];
  private age = [
    { no: 0, text: "전체" },
    { no: 1, text: "10대" },
    { no: 2, text: "20대" },
    { no: 3, text: "30대" },
    { no: 4, text: "40대" },
    { no: 5, text: "50대" },
    { no: 6, text: "60대" },
    { no: 7, text: "70대" },
  ];
  private interest = [
    { no: 0, text: "전체" },
    { no: 1, text: "사회/정치" },
    { no: 2, text: "건강/의학" },
    { no: 3, text: "경제/금융" },
    { no: 4, text: "교육/학문" },
    { no: 5, text: "육아/결혼/가족" },
    { no: 6, text: "반려동물" },
    { no: 7, text: "요리/레시피" },
    { no: 8, text: "맛집" },
    { no: 9, text: "뷰티" },
    { no: 10, text: "인테리어/DIY" },
    { no: 11, text: "여행" },
    { no: 12, text: "다이어트" },
    { no: 13, text: "IT" },
    { no: 14, text: "게임" },
    { no: 15, text: "자동차" },
    { no: 16, text: "스포츠/레저" },
    { no: 17, text: "스타/연예인" },
    { no: 18, text: "문화/공연/예술" },
    { no: 19, text: "환경" },
    { no: 20, text: "기타" },
  ];
  private family = [
    { no: 0, text: "전체" },
    { no: 1, text: "1인 가구" },
    { no: 2, text: "2인 가구" },
    { no: 3, text: "3인 가구 이상" },
  ];

  private married = [
    { no: 0, text: "전체" },
    { no: 1, text: "기혼" },
    { no: 2, text: "미혼" },
    { no: 3, text: "기타(이혼,사별)" },
  ];
  private lifeStage = [
    { no: 0, text: "전체" },
    { no: 1, text: "동거인과 함께 거주하는 미성년자 또는 성인 미혼자" },
    { no: 2, text: "혼자사는 성인(1인가구)" },
    { no: 3, text: "자녀가 없는 기혼자" },
    { no: 4, text: "미취학 아동(0~6세)이 있는 기혼자" },
    { no: 5, text: "초등학생 자녀(7~12세)가 있는 기혼자" },
    { no: 6, text: "중고생 자녀(13~18세)가 있는 기혼자" },
    { no: 7, text: "성인 자녀(19세 이상)가 있는 기혼자" },
    { no: 8, text: "자녀가 독립하여 부부 둘만 사는 기혼자" },
  ];
  private filterValue: IFilterValue = {
    selectedGender: [{ no: 0, text: "전체" }],
    selectedAddress: [{ no: 0, text: "전체" }],
    selectedInterest: [{ no: 0, text: "전체" }],
    selectedAge: [{ no: 0, text: "전체" }],
    selectedFamily: [{ no: 0, text: "전체" }],
    selectedMarried: [{ no: 0, text: "전체" }],
    selectedLifeStage: [{ no: 0, text: "전체" }],
    dates: [],
    month: "",
    searchPeriod: "1",
    category: [],
  };
  private All = "전체";
  private mainCategoryList = [
    "건강/운동",
    "교육",
    "금융",
    "라이프스타일",
    "부동산/홈 인테리어",
    "비즈니스",
    "소셜",
    "쇼핑",
    "엔터테인먼트",
    "의료",
  ];
  private detailCategories: IDetailCategories = {
    ganada: [],
    ramaba: [],
    saaja: [],
    chakatapaha: [],
    etc: [],
  };
  private categoryMenu = {
    ganada: false,
    ramaba: false,
    saaja: false,
    chakatapaha: false,
    etc: false,
  };
  private moreCategory = false;
  private categoryList: string[] = [];

  private selectedItemsLength = 0;
  private datePickerType = "date";
  private monthPickerType = "month";
  private pickerMode = true;
  private lastDate = "";
  $dialog: any;

  async mounted() {
    await this.getLastDate();
    await this.getDefaultDate();
    await this.getCategory();
    await this.setUp(false);
  }

  async getDefaultDate() {
    this.pickerMode = true;
    this.datePickerType = "date";

    const data = await this.$store.dispatch("dashboard/getLastDate", {});
    this.filterValue.dates = [];
    this.filterValue.dates.push(
      moment(moment(data, "YYYY-MM-DD")).add(-6, "day").format("YYYY-MM-DD")
    );
    this.filterValue.dates.push(
      moment(moment(data, "YYYY-MM-DD")).format("YYYY-MM-DD")
    );
  }

  async DefaultMonth() {
    this.pickerMode = false;
    this.datePickerType = "month";
    this.filterValue.month = moment(moment(this.lastDate, "YYYY-MM-DD")).format(
      "YYYY-MM"
    );
  }
  async DefaultYear() {
    this.pickerMode = false;
    this.datePickerType = "month";
    this.filterValue.month = moment(moment(this.lastDate, "YYYY-MM-DD"))
      .add(-11, "month")
      .format("YYYY-MM");
  }

  get dateRangeText() {
    if (this.filterValue.searchPeriod === "1") {
      return this.filterValue.dates.join(" ~ ");
    } else if (this.filterValue.searchPeriod === "2") {
      return this.filterValue.month;
    } else {
      return `${this.filterValue.month} ~ ${moment(
        moment(this.filterValue.month, "YYYY-MM")
      )
        .add(+11, "month")
        .format("YYYY-MM")}`;
    }
  }

  get maxDate() {
    const date = this.lastDate.toString();
    if (this.filterValue.searchPeriod === "1") {
      return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;
    } else {
      return `${date.slice(0, 4)}-${date.slice(4, 6)}`;
    }
  }
  getDay(date: any) {
    const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
    let i = new Date(date).getDay();
    return daysOfWeek[i];
  }
  getMonth(date: any) {
    const monthName = [
      "1월",
      "2월",
      "3월",
      "4월",
      "5월",
      "6월",
      "7월",
      "8월",
      "9월",
      "10월",
      "11월",
      "12월",
    ];

    let i = new Date(date).getMonth();
    return monthName[i];
  }
  getHeaderTitleMonth(date: any) {
    const monthName = [
      "1월",
      "2월",
      "3월",
      "4월",
      "5월",
      "6월",
      "7월",
      "8월",
      "9월",
      "10월",
      "11월",
      "12월",
    ];
    let i = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    const month = monthName[i];
    return `${year}년 ${month}`;
  }

  @Watch("filterValue.dates")
  computeLastDate() {
    if (this.pickerMode) {
      this.filterValue.dates[1] = moment(
        moment(this.filterValue.dates[0], "YYYY-MM-DD")
      )
        .add(+6, "day")
        .format("YYYY-MM-DD");
    }
  }
  @Watch("All")
  selectAll() {
    if (this.All === "전체") {
      this.filterValue.category = [];
    } else {
      if (this.filterValue.category.length == 0) {
        setTimeout(() => {
          this.All = "전체";
        }, 10);
      }
    }
  }

  @Watch("filterValue.category")
  checkSelectedCategoryLength() {
    if (this.filterValue.category.length > 0) {
      this.All = "";
    }
    if (this.filterValue.category.length == 0) {
      this.All = "전체";
    }

    if (this.filterValue.category.length > 10) {
      this.filterValue.category.pop();
      this.showDialog("카테고리는 <u>최대 10개까지</u> 가능합니다");
    }
  }

  showDialog(msg: string) {
    this.dialogConfirm = false;
    this.dialogButtonText = "확인";
    this.alertDialog = true;
    this.dialogLogo = "ExclamationMark";
    this.dialMsg = msg;
  }
  hideDialog() {
    this.alertDialog = false;
    this.saveDialog = false;
  }
  confirm(msg: string) {
    this.dialogButtonText = "취소";
    this.alertDialog = true;
    this.dialMsg = msg;
    this.dialogConfirm = true;
  }
  saveConfirm() {
    this.saveDialog = true;
    this.dialMsg = "설정한 필터를 적용 하시겠습니까?";
  }

  deleteCategoryChip(chip: string) {
    this.filterValue.category = this.filterValue.category.filter(
      (v: string) => v !== chip
    );
  }

  deleteDetailChip(key: keyof IFilterValue, chip: FilterItem) {
    (this.filterValue[key] as FilterItem[]) = (
      this.filterValue[key] as FilterItem[]
    ).filter((element) => element.no !== chip.no);
    if (this.filterValue[key].length === 0) {
      (this.filterValue[key] as FilterItem[]) = [{ no: 0, text: "전체" }];
    }
  }

  getDisplayTooltip(text: string) {
    return text.length <= 7;
  }

  getChipText(text: string) {
    if (text.length > 7) {
      return text.substring(0, 6) + "...";
    } else return text;
  }

  get checkSelectedItemsLength() {
    this.selectedItemsLength =
      this.filterValue.selectedGender.filter((v: { no: number }) => v.no !== 0)
        .length +
      this.filterValue.selectedAddress.filter((v: { no: number }) => v.no !== 0)
        .length +
      this.filterValue.selectedInterest.filter(
        (v: { no: number }) => v.no !== 0
      ).length +
      this.filterValue.selectedAge.filter((v: { no: number }) => v.no !== 0)
        .length +
      this.filterValue.selectedFamily.filter((v: { no: number }) => v.no !== 0)
        .length +
      this.filterValue.selectedMarried.filter((v: { no: number }) => v.no !== 0)
        .length +
      this.filterValue.selectedLifeStage.filter(
        (v: { no: number }) => v.no !== 0
      ).length;

    return this.selectedItemsLength <= 30;
  }
  checkAll(filterItem: FilterItem[]) {
    return filterItem.find(({ text }) => text === "전체");
  }
  getValueLength(key: keyof IFilterValue) {
    switch (key) {
      case "selectedGender": {
        return this.gender.length - 1;
      }
      case "selectedAddress": {
        return this.address.length - 1;
      }
      case "selectedInterest": {
        return this.interest.length - 1;
      }
      case "selectedAge": {
        return this.age.length - 1;
      }
      case "selectedFamily": {
        return this.family.length - 1;
      }
      case "selectedMarried": {
        return this.married.length - 1;
      }
      case "selectedLifeStage": {
        return this.lifeStage.length - 1;
      }
    }
  }
  AllSelectedCheck(key: keyof IFilterValue) {
    if (this.filterValue[key].length !== 0) {
      if (
        (this.filterValue[key] as FilterItem[])[
          this.filterValue[key].length - 1
        ].no === 0
      ) {
        if (this.checkAll(this.filterValue[key] as FilterItem[])) {
          (this.filterValue[key] as FilterItem[]) = [{ no: 0, text: "전체" }];
        }
      } else {
        (this.filterValue[key] as FilterItem[]) = (
          this.filterValue[key] as FilterItem[]
        ).filter((v) => v.no !== 0);
      }
      if (this.checkSelectedItemsLength) {
        if (this.filterValue[key].length === this.getValueLength(key)) {
          (this.filterValue[key] as FilterItem[]) = [{ no: 0, text: "전체" }];
        }
      } else {
        this.showDialog("상세분류 필터는 <u>최대 30개까지</u> 선택 가능합니다");
        (this.filterValue[key] as FilterItem[]).pop();
        if (this.filterValue[key].length === 0) {
          (this.filterValue[key] as FilterItem[]) = [{ no: 0, text: "전체" }];
        }
      }
    } else {
      if (this.filterValue[key].length === 0) {
        (this.filterValue[key] as FilterItem[]) = [{ no: 0, text: "전체" }];
      }
    }
  }

  async initFilter() {
    this.filterValue.selectedGender = [{ no: 0, text: "전체" }];
    this.filterValue.selectedAddress = [{ no: 0, text: "전체" }];
    this.filterValue.selectedInterest = [{ no: 0, text: "전체" }];
    this.filterValue.selectedAge = [{ no: 0, text: "전체" }];
    this.filterValue.selectedFamily = [{ no: 0, text: "전체" }];
    this.filterValue.selectedMarried = [{ no: 0, text: "전체" }];
    this.filterValue.selectedLifeStage = [{ no: 0, text: "전체" }];
    this.filterValue.category = [];
    this.selectedItemsLength = 0;
    this.moreCategory = false;
    this.pickerMode = true;
    this.filterValue.searchPeriod = "1";
    await this.getDefaultDate();
    this.filterValue.month = "";

    this.alertDialog = false;
    await this.$dialog.notify.success("초기화 되었습니다.");
  }

  async setUp(confirmMode: boolean) {
    this.saveDialog = false;
    if (this.filterValue.dates == null && this.filterValue.month === "") {
      this.showDialog("기간을 선택해 주세요.");
      return false;
    }
    if (confirmMode) {
      const payload = await this.$store.dispatch(
        "dashboard/getPayloadValue",
        this.filterValue
      );
      console.log(payload);
      const result = await this.$store.dispatch("dashboard/checkData", payload);
      if (result) {
        this.$store.commit("dashboard/setFilerValue", this.filterValue);
        this.$emit("draw");
      } else {
        this.showDialog(
          "다시 조건을 선택해주세요.<br>선택하신 조건에 해당하는 데이터가 없습니다."
        );
        return false;
      }
    } else {
      this.$store.commit("dashboard/setFilerValue", this.filterValue);
      this.$emit("draw");
    }
  }
  async getLastDate() {
    this.lastDate = await this.$store.dispatch("dashboard/getLastDate", {});
  }

  async getCategory() {
    this.categoryList = await this.$store.dispatch(
      "dashboard/getCategoryList",
      {}
    );

    this.categoryList = this.categoryList.sort();
    this.categoryList.forEach((item: string) => {
      this.regExpTest(item);
    });
  }

  regExpTest(str: string) {
    switch (true) {
      case /[가-딯까-띻]/i.test(str.slice(0, 1)): {
        this.detailCategories.ganada.push(str);
        break;
      }
      case /[라-빟라-삫]/i.test(str.slice(0, 1)): {
        this.detailCategories.ramaba.push(str);
        break;
      }
      case /[사-짛싸-찧]/i.test(str.slice(0, 1)): {
        this.detailCategories.saaja.push(str);
        break;
      }
      case /[차-힣]/i.test(str.slice(0, 1)): {
        this.detailCategories.chakatapaha.push(str);
        break;
      }
      default: {
        this.detailCategories.etc.push(str);
        break;
      }
    }
  }
  getFilterValueExceptAll(key: keyof IFilterValue) {
    return (this.filterValue[key] as FilterItem[]).filter((v) => v.no !== 0);
  }

  getSelectLabel(key: keyof IFilterValue) {
    if (
      (this.filterValue[key] as FilterItem[]).find(
        ({ text }) => text === "전체"
      )
    ) {
      return "전체";
    } else {
      return this.filterValue[key].length + "개";
    }
  }
}
</script>

<style scoped>
.filer-chips {
  font-family: "Noto Sans KR" !important;
  background: rgba(0, 168, 128, 0.1) !important;
}
::v-deep .v-list-item__title {
  color: black;
}
::v-deep .v-input__slot {
  height: 35px !important;
  /*max-height: 35px !important;*/
  min-height: 37px !important;
}

.filer-detail-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #161d1d;
}
.category-btn {
  color: #757575;
}
.filter-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #001f2b;
}
</style>
