<template>
  <div>
    <v-card>
      <v-card-title class="justify-center">Message</v-card-title>
      <hr />
      <v-card-text class="pa-5">
        <div class="d-flex justify-center text-center mb-5">
          <div>
            총 {{ selectedItems.length }}명의 유저에게<br />앱 삭제 메세지를
            전송하시겠습니까?
          </div>
        </div>
        <v-textarea counter outlined v-model="messageContent"></v-textarea>
      </v-card-text>
      <v-card-actions class="justify-center mr-2 pb-4" style="width: 100%">
        <v-btn
          color="primary"
          dark
          rounded
          @click="sendMessage"
          style="width: 40%"
        >
          <slot name="btnname"></slot>
        </v-btn>
        <v-btn
          color="grey darken-2"
          dark
          rounded
          @click="closeSelf"
          style="width: 40%"
        >
          <slot name="closebtnname"></slot>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="alertDialog" width="500" persistent>
      <MyDialog @hide="hideDialog" :mode="alertCloseMode" :logo="dialogLogo">
        <template #body>
          <h3>{{ dialMsg }}</h3>
        </template>
        <template #btnname> 확인 </template>
      </MyDialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import MyDialog from "@/components/MyDialog.vue";

@Component({ components: { MyDialog } })
export default class MessageDialog extends Vue {
  @Prop() selectedItems!: any[];

  private alertDialog = false;
  private alertCloseMode = false;
  private dialMsg = "";
  private dialogLogo = "";
  private messageContent = "";
  private mobileList: any[] = [];
  async sendMessage() {
    if (this.messageContent.length === 0) {
      this.showDialog("메세지를 입력해 주세요.", false, "X");
      return false;
    }
    this.selectedItems.forEach((item) => {
      this.mobileList.push({ mobile: item.userPhoneNumber });
    });

    try {
      const res = await this.$store.dispatch("sendMessage", {
        messageContent: this.messageContent,
        receiver: this.mobileList,
        isAuth: false,
      });
      if (!res.result) {
        this.showDialog("발송 실패 ERROR CODE : " + res.status, false, "X");
        return false;
      } else {
        this.showDialog("전송이 완료되었습니다.", true, "Check");
        this.mobileList = [];
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  showDialog(msg: string, close: boolean, logo: string) {
    this.dialMsg = msg;
    this.dialogLogo = logo;
    this.alertCloseMode = close;
    this.alertDialog = true;
  }
  hideDialog(close: boolean) {
    this.alertDialog = false;
    if (close) this.closeSelf();
  }
  closeSelf() {
    this.messageContent = "";
    this.$emit("hide");
  }
}
</script>

<style scoped></style>
