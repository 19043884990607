<template>
  <div></div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class TokenCheck extends Vue {
  mounted() {
    this.$store.dispatch("getUserId");
  }
}
</script>

<style scoped></style>
